.terms-of-service.contrainer-wrapper {
    display: flex;
    justify-content: center;
    min-height: 100vh;
    font-family: 'Mplus 1p', sans-serif;
}

.terms-of-service.contrainer-wrapper.horizontal {
    display: flex;
    justify-content: center;
    width: 100vh;
}

.terms-of-service .contrainer-block {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 50px 0;
}

.terms-of-service .title {
    text-align: center;
    margin: 40px 0;
    font-size: 2.5rem;
    color: #222629;
}

.terms-of-service .logo {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.terms-of-service .logo img {
    vertical-align: middle;
    border-style: none;
    width: 150px;
}

.terms-of-service .form-wrapper {
    background: #f2f2f8;
    /* box-shadow: 0px 2px 6px #cecfd9; */
    padding: 24px;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 600px;
}
.terms-of-service .form-wrapper p{
    margin-bottom: 0.3rem;
    line-height: 1.2rem;
    font-size: 0.9rem;
}
.terms-of-service .link {
    padding:24px;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 600px;
}
.terms-of-service .link a span{
    text-transform: lowercase !important;
}

.terms-of-service .form-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    min-height: 40px;
}

.terms-of-service .header-info {
    font-size: 20px;
    font-weight: 500;
}

.terms-of-service .form-container {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.terms-of-service .fields-container {
    position: relative;
}

.terms-of-service .caution-title{
    color: #424648;
    font-weight: bold;
    font-size: larger;
    margin-bottom: 10px;
}

.terms-of-service .form-bottom {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}

.terms-of-service .footer {
    margin-top: 17px;
    margin-bottom: 16px;
    position: fixed;
    bottom: 0;
    right: 49px;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    color: #9398ab;
}
