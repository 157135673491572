.linkage.contrainer-wrapper {
    display: flex;
    justify-content: center;
    min-height: 100vh;
    background-color: #f2f2f8;
    font-family: 'Mplus 1p', sans-serif;
}

.linkage.contrainer-wrapper .horizontal {
    display: flex;
    justify-content: center;
    width: 100vh;
}

.linkage .contrainer-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0px 0 0 0;
    width: 100%;
}

.linkage .logo {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
}

.linkage .logo img {
    vertical-align: middle;
    border-style: none;
    max-width: 500px;
    width: 100%;
    height: 100px;
}

.linkage .logo-horizontal {
    display: flex;
    margin-bottom: 24px;
    min-height: 30px;
    height: 60px;
}

.linkage .logo-horizontal img {
    vertical-align: middle;
    border-style: none;
}

.linkage .form-wrapper {
    background: #FFFFFF;
    box-shadow: 0px 2px 6px #cecfd9;
    border-radius: 6px;
    padding: 28px;
    position: relative;
    display: flex;
    flex-direction: column;
}

.linkage .form-wrapper form {
    display: flex;
    flex-direction: column;
}

.linkage .form-container {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.linkage .form-bottom {
    display: flex;
    justify-content: space-between;
}

.linkage .form-bottom {
    display: flex;
    justify-content: space-between;
}

.linkage .form-bottom .wrapper-button-cancel .btn {
    margin-right: 8px;
}

.linkage .form-bottom .wrapper-button-submit .btn {
    margin-left: 8px;
}

.linkage .footer {
    margin-top: 17px;
    margin-bottom: 16px;
    position: fixed;
    bottom: 0;
    right: 49px;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    color: #9398ab;
}