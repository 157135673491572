/* [shortcut] margin */

.lz-m-10 {
    margin: 10px;
}

.lz-m-20 {
    margin: 20px;
}

.lz-m-40 {
    margin: 40px;
}

.lz-m-0-10 {
    margin: 0 10px;
}

.lz-m-0-20 {
    margin: 0 20px;
}

.lz-m-0-40 {
    margin: 0 40px;
}

.lz-m-0-60 {
    margin: 0 60px;
}

.lz-m-0-80 {
    margin: 0 80px;
}


/* [shortcut] margin-top */

.lz-mt-10 {
    margin-top: 10px;
}

.lz-mt-20 {
    margin-top: 20px;
}

.lz-mt-40 {
    margin-top: 40px;
}

.lz-mt-80 {
    margin-top: 80px;
}


/* [shortcut] margin-bottom */
.lz-mb-0 {
    margin-bottom: 0px;
}

.lz-mb-10 {
    margin-bottom: 10px;
}

.lz-mb-20 {
    margin-bottom: 20px;
}

.lz-mb-40 {
    margin-bottom: 40px;
}


/* [shortcut] margin-right */

.lz-ml-4 {
    margin-left: 4px;
}

.lz-ml-10 {
    margin-left: 10px;
}

.lz-ml-20 {
    margin-left: 20px;
}


/* [shortcut] margin-right */

.lz-mr-4 {
    margin-right: 4px;
}

.lz-mr-8 {
    margin-right: 8px;
}

.lz-p-20 {
    padding: 20px;
}

.lz-p-40 {
    padding: 40px;
}

.lz-p-80 {
    padding: 80px;
}


/* [shortcut] width */

.lz-w-400 {
    width: 400px;
}

.lz-w-600 {
    width: 600px;
}

.lz-w-800 {
    width: 800px;
}


/* */

.lz-flex {
    display: flex;
}

.lz-flex-1 {
    flex: 1;
}


/* */

.lz-txt-c {
    text-align: center;
}

.lz-txt-r {
    text-align: right;
}

.lz-nowrap {
    white-space: nowrap;
}

.lz-bob-success {
    padding: 4px 10px;
    border: 1px solid #2e7d32;
    border-radius: 6px;
}

.lz-bob-disabled {
    padding: 4px 10px;
    border: 1px solid #929699;
    border-radius: 6px;
}

.lz-bob-warning {
    padding: 4px 10px;
    border: 1px solid #f47925;
    border-radius: 6px;
}


/* */

.lz-btn-mini button {
    min-width: auto !important;
    padding: 0 10px !important;
    margin: auto !important;
    height: 36px !important;
    white-space: nowrap;
}

/* */

.input-textbox input[type=search] {
    width: 300px;
    border: 1px solid #fff;
    background: #f2f2f8;
    padding: 5px 15px 5px 20px;
    height: 36px;
    margin-left: 10px;
    border-radius: 18px;
    box-sizing: border-box;
    font-size: 0.92rem;
    transition: all .3s;
    line-height: 36px;
}

.input-textbox input[type=search]:focus {
    outline: 0;
    border: 1px solid #40a9ff;
}


/* */

.organization-info {
    display: flex;
    background-color: #ddd;
    border-radius: 4px;
    margin-bottom: 4px;
    padding: 8px;
    cursor: pointer;
}

.organization-info:hover {
    background-color: #ccc;
}

.organization-name {
    flex: 1;
    padding-left: 10px;
    line-height: 30px;
}

.organization-items {
    padding-left: 40px;
}

.organization-menu button {
    width: 30px;
    height: 30px;
    border: 0;
    background-color: transparent;
    border-radius: 4px;
}

.organization-menu button:hover {
    background-color: #888;
}

.form-control:disabled,
.form-control[readonly] {
    background-color: #e2e2e8;
}

/* */

.select-tab {
    display: flex;
}

.select-tab-item {
    padding: 5px 30px;
    cursor: pointer;
    color: #929699;
    border-bottom: 1px solid #e2e2e8;
    user-select: none;
    font-size: 14px;
}

.select-tab-item:hover {
    background-color: #f2f2f8;
    border-bottom: 1px solid #1069D5;
}

.select-tab-item.active {
    color: #1069D5;
    border-bottom: 4px solid #1069D5;
}


/* */

.paginate {
    display: flex;
}

.paginate-info {
    margin: 6px 10px;
    font-size: 14px;
    line-height: 24px;
}

.paginate-info span:nth-child(1):after {
    content: "-";
    margin: 0 4px;
}

.paginate-info span:nth-child(2):after {
    content: "/";
    margin: 0 4px;
}

.paginate-nav {
    white-space: nowrap;
}

.paginate-nav button {
    border: 0;
    background-color: transparent;
    width: 36px;
    height: 36px;
    margin: 0 4px;
    overflow: hidden;
    text-align: center;
    border-radius: 50%;
}

.paginate-nav button:hover {
    background-color: #e2e2e8;
}

.paginate-nav button:disabled {
    cursor: not-allowed;
}


/* GLOBAL PageSettings */
.clickable {
    cursor: pointer;
}

.page-settings table {
    width: 100%;
}

.page-settings table thead th {
    font-size: 0.75rem;
    color: #828688;
    font-weight: 500;
}



.page-settings table tbody tr.item:hover {
    background-color: #f2f2f8;
    cursor: pointer;
}

.userInfoTree td:first-child {
    color: #535963;
    font-size: 0.81rem;
}

.userInfoTree td:last-child {
    font-size: 0.937rem;
}

/* PageSettings-Organization */

#page-settings-organization #organization-menu {
    padding: 10px;
    border-bottom: 1px solid #e2e2e8;
}


/* PageSettings-User */

#page-settings-user table thead tr th:nth-child(1) {
    width: 1%;
}

#page-settings-user table tbody td img {
    margin-left: 10px;
}


/* PageSettings-Stamp */

#page-settings-stamp table tbody button.not-available {
    background-color: #d31a1a;
    border: 0;
    border-radius: 4px;
    color: #fff;
    padding: 4px 10px;
}

#page-settings-stamp table tbody button:hover.not-available {
    background-color: #fc1e1e;
}

#page-settings-stamp table tbody button.available {
    background-color: #29d31a;
    border: 0;
    border-radius: 4px;
    color: #fff;
    padding: 4px 10px;
}

#page-settings-stamp table tbody button:hover.available {
    background-color: #1efc1e;
}

.user-list {
    height: 500px;
    overflow-y: scroll;
    border: 1px solid #e2e2e8;
    background-color: #f5f5fa;
}

.user-list:hover {
    background-color: #f2f2f8;
}

.user-list-item {
    font-size: .875rem;
    background-color: #fff;
    margin: 10px;
    box-shadow: 0 0 4px 1px #ddd;
    overflow: hidden;
    border-radius: 20px;
}

.user-list-item .iconImg svg {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 10px;
    vertical-align: middle;
    text-align: center;
}

.user-list-item button {
    margin: 5px;
}

.user-title {
    line-height: 20px;
    margin: 10px 0;
}



#settings-stamp table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
}

#settings-stamp table tr {
    border-bottom: 1px solid #888;
}

#settings-stamp table tr:last-child {
    border: 0;
}

#settings-stamp table input {
    width: 120px;
    height: 38px;
    border: 0;
}

#settings-stamp table input:read-only {
    background-color: #e2e2e8;
}

#settings-stamp table tr td:nth-child(1) input {
    width: 60px;
}

#settings-stamp table tr td:nth-child(1),
#settings-stamp table tr td:nth-child(2) {
    width: 1%;
}

#settings-stamp table tbody td {
    padding: 4px 1px;
}

.stamp-label {
    margin-bottom: 4px;
}

.stamp-itemlist {
    padding: 10px;
    height: 600px;
    border-radius: 4px;
    border: 1px solid #ced4da;
    overflow-y: scroll;
}

.stamp-item label {
    display: block;
    /* cursor: pointer; */
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
}

#settings-stamp .form-row,
#settings-stamp .stamp-item,
#settings-stamp .stamp-item-row {
    height: 100%;
}

.notification-list {
    border-top: 1px solid #cecfd9;
}

.notification-item {
    padding: 24px;
    border-bottom: 1px solid #cecfd9;
}

.notification-item[data-status=read] {
    background-color: #f2f2f8;
}

.notification-head {
    display: flex;
}

.notification-title {
    flex: 1;
    font-weight: bolder;
    margin-bottom: 20px;
}

.notification-title span.unread {
    position: relative;
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    top: 0px;
    left: 10px;
    background-color: #1890ff;
}

.profile-box {
    border: 1px solid #e2e2e8;
    border-radius: 8px;
    background-color: #f2f2f8;
}

.profile-box .box-head {
    margin: 20px 20px 0 20px;
    display: flex;
}

.box-title {
    flex: 1;
}

.profile-box table {
    width: 100%;
}

.profile-box table tr {
    border-bottom: 1px solid #e2e2e8;
}

.profile-box table tr:last-child {
    border-bottom: 0;
}

.profile-box table td div {
    padding: 10px;
}

.settings .profile-menu button {
    padding: 10px 30px;
    background: #FFFFFF;
    color: #1069D5;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.26);
    border-radius: 6px;
    border: 0;
    margin-right: 8px;
}

.settings .profile-menu button:hover {
    background: #e2e2e8;
}

.settings .profile-menu button:active {
    box-shadow: inset 0 1px 6px rgba(0, 0, 0, 0.26);
}

.profile-pic {
    margin-top: 60px;
    text-align: center;
}

.profile-pic-edit {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    overflow: hidden;
    display: inline-block;
    cursor: pointer;
    transition: all 0.5s;
}

.profile-pic-edit:hover {
    box-shadow: 0 5px 35px 0px rgba(0, 123, 255, 0.45);
}

.profile-pic-edit>div {
    width: 150px;
    height: 150px;
    transition: all 0.5s;
    background: #e2e2e8;
}

.profile-pic-edit svg {
    width: 120px;
    text-align: center;
    margin: 0 auto;
    line-height: 100px;
    font-size: 90px;
    text-indent: -15px;
    color: #8e90a4;
    transition: all 0.5s;
}

.profile-pic-edit:hover>div {
    background: #007bff73;
}

.profile-pic-edit:hover svg {
    color: #2438ab73;
}

.profile-dialog {
    max-width: 800px;
}

.jcan-cert-upload {
    height: 250px;
    background-color: #e2e2e8;
    text-align: center;
    box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.16);
}

.jcan-cert-upload i {
    display: inline-block;
    height: 190px;
    margin: 40px 0;
    width: 150px;
    line-height: 190px;
    background-color: #f2f2f8;
    border: 1px dashed #CECFD9;
}

table.profile-certificates thead th{
  padding: 0 1em 5px;
  font-weight: normal;
  font-size: 0.75rem;
  border-bottom: 1px solid #e2e2e8;
  color: #535963;
}
table.profile-certificates thead th:first-child{
  width: 11em;
}

table.profile-certificates tr td:last-child {
    width: 1%;
    white-space: nowrap;
}

table.profile-certificates tr td:last-child button {
    margin-left: 6px;
    border: 0;
    background-color: transparent;
    color: #1069D5;
}

table.profile-certificates tr td:last-child button:disabled {
    cursor: not-allowed;
    color: #ccc;
}


/* */

#settings-user-form {
    background-color: transparent;
}

#settings-user-form .form-label {
    margin-bottom: 0;
}

#settings-user-form .form-group {
    height: 84px;
    margin-bottom: 10px;
}

#settings-stamp .form-group.status,
#settings-user-form .form-group.status {
    height: 46px;
    margin: 0;
    padding-top: 10px;
    padding-left: 8px;
}

#settings-user-form .form-control {
    min-height: 46px;
}

.profile-certificates td {
    padding: 10px;
}

.profile-api-token input[type=text] {
    background-color: #fff;
}

.iconBtn {
    padding: 6px 10px !important;
    min-width: auto !important;
    height: auto !important;
    margin: 0 0 0 4px !important;
    display: inline !important;
    color: #1069D5;
}

.iconBtn:hover {
    color: #0056b3;
}

.dialog-form .form-group {
    height: auto;
}

.dialog-form .form-group label {
    margin-bottom: 0;
}

.profile-box .form-group {
    height: auto;
}

.org-head {
    margin: 4px;
    padding: 10px;
    line-height: 30px;
    box-shadow: 0 0 4px 2px #ccc;
    display: flex;
}

.org-head button {
    width: 30px;
    height: 30px;
    border: 0;
    background-color: transparent;
}

.org-head button .org-toggle {
    font-size: 0.65rem;
}

.org-body {
    opacity: 1;
    height: auto;
    overflow: auto;
    padding-left: 40px;
    transition: all .3s linear;
}

.org-head .org-tag {
    font-size: 0.75rem;
    color: #626669;
}

.org-head .org-name {
    font-weight: 500;
}

.org-body.org-collapse {
    opacity: 0;
    height: 0;
}

.org-selector-label {
    margin-bottom: 4px;
}

.org-selector-itemlist {
    padding: 20px;
    height: 400px;
    border-radius: 4px;
    border: 1px solid #ced4da;
    overflow-y: scroll;
}

.org-selector-item label {
    display: block;
    cursor: pointer;
    box-shadow: 0 0 6px 2px #e2e2e8;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
}

.org-selector-item div {
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 4px;
    border: 1px solid #ced4da;
}

.org-toggle {
    font-size: 80%;
}

.user-head {
    height: 30px;
    margin: 4px;
    padding: 0 10px;
    line-height: 30px;
    background-color: #f2f2f8;
    font-size: 0.81rem;
    border-radius: 15px;
    font-weight: 500;
}

.guest-head {
    height: 30px;
    margin: 4px;
    padding: 0 0 0 10px;
    line-height: 28px;
    display: flex;
    background-color: #e1edff;
    font-size: 0.81rem;
    border-radius: 15px;
    box-sizing: border-box;
    font-weight: 500;
}

.guest-icon {
    display: inline-block;
    line-height: 28px;
    height: 28px;
    background-color: #bad3f9;
    font-size: 0.75rem;
    border-radius: 0 13px 13px 0;
    padding: 0 8px;
    font-weight: 500;
    margin-right: 0px;
}

.checkboxBtn input[type=checkbox] {
    display: none;
}

.checkboxBtn input[type=checkbox]:checked+div {
    border-color: #007bff;
    background-color: #f1f8ff;
}

.user-selection-error {
    text-align: right;
    color: red;
}

.app-cert-dialog {
    max-width: 90%;
}

/* #settings-app-cert .user-list {
    height: 500px;
    overflow-y: scroll;
    border: 1px solid #e2e2e8;
} */

/* #settings-app-cert .user-list-item {
    margin: 10px;
    box-shadow: 0 0 4px 1px #e2e2e8;
    overflow: hidden;
} */

#settings-app-cert .user-title.smmallT {
    min-width: 180px;
    font-size: 0.75rem;
    color: #535963;
}

#settings-app-cert .user-title.cheader {
    margin-bottom: 2px;
}

#settings-app-cert .user-title.cfooter {
    margin-top: 2px;
}

/* #settings-app-cert .user-title span {
    margin-right: 6px;
} */

#settings-app-cert table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
}

#settings-app-cert table tr {
    border-bottom: 1px solid #888;
}

#settings-app-cert table tr:last-child {
    border: 0;
}

#settings-app-cert table input {
    width: 120px;
    height: 38px;
    border: 0;
}

#settings-app-cert table input:read-only {
    background-color: #e2e2e8;
}

#settings-app-cert table tr td:nth-child(1) input {
    width: 60px;
}

#settings-app-cert table tr td:nth-child(1),
#settings-app-cert table tr td:nth-child(2) {
    width: 1%;
}

#settings-app-cert table tbody td {
    padding: 4px 1px;
}

.table-wrap {
    overflow: auto;
}

.appcert-label {
    margin-bottom: 4px;
}

.appcert-itemlist {
    padding: 10px;
    height: 800px;
    border-radius: 4px;
    border: 1px solid #ced4da;
    overflow-y: scroll;
}

.appcert-item label {
    display: block;
    /* cursor: pointer; */
    /* box-shadow: 0 0 6px 2px #e2e2e8; */
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
}

.appcert-item-row {
    margin: 5px;
    border: 1px solid #ced4da;
}

.appcert-item div {
    padding: 5px;
    margin-bottom: 5px;
    border-radius: 4px;
    /* border: 1px solid #ced4da; */
}

.stamp-edit {
    font-size: 25px;
    vertical-align: middle;
    text-align: center;
    color: #848484;
}

.stamp-edit .inner-container {
    height: 25px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: #ec163f;
}

.stamp-delete,
.stamp-box {
    text-align: center;
    width: 60px;
}

.modal-dialog form#stamp-form .stamp-user-list {
    overflow: auto;
    width: 100%;
    min-height: 200px;
}

.modal-dialog form#stamp-form .form-group-canvas {
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 24px;
}

.modal-dialog form#stamp-form .form-group-canvas .stamp-preview {
    width: 100%;
}

.tenants-detail-dialog {
    max-width: 1000px;
}

.tenant-info-item .services-label {
    white-space: nowrap;
}

.tenant-info-item .services-label .icon {
    width: 24px;
    height: 24px;
}

.tenant-info-item .services-label p.label {
    margin-right: 8px;
}

.tenant-info-item .services-label p.label:not(:last-child)::after {
    content: ",";
}

.tenant-info-item .services-label p.label span {
    padding: 0 4px;
}

.tenant-info-item {
    padding: 16px;
    display: flex;
    align-items: center;
}

.tenant-info-item p.label {
    margin: 0;
}

.tenant-info-item>.label-value,
.tenant-info-item>.label {
    width: 196px;
    display: flex;
    align-items: center;
}

.tenant-info-item>.label-value .value {
    margin-left: 10px;
}

.tenant-info-item .label,
.tenant-info-item .value {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #4C4F5E;
    display: flex;
    align-items: center;
}

.setting-sso {
    max-width: 600px;
}

.setting-restrictIp {
    max-width: 600px;
}


.email-controller-chkbox {
  cursor: pointer;
}

/* Button */
#page-settings-guest .btn-addGuest {
  min-width: 128px;
}

/*ellipsis*/
.text-ellipsis {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

