*:not(.material-icons-outlined):not(.material-icons) {
    font-family: 'Noto Sans JP', 'Roboto', 'Segoe UI', -apple-system, BlinkMacSystemFont, 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
}

body {
    margin: 0;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #062b60;
}

.secondaryText {
    color: #535963;
}

.secondaryTextW {
    color: #e2e2e8;
}

.smallText {
    font-size: 0.81rem;
}

.xsmallText {
    font-size: 0.75rem;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a,
tr,
li,
button {
    transition: all .2s ease-in-out;
}

a:hover,
button:hover {
    text-decoration: none;
}

a.as-link:hover {
    color: #fff;
}

.main-body-loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
}

.form-wrapper .form-row input,
.form-wrapper .form-row select {
    height: 40px;
    margin-bottom: 0;
}

.auth .form-group {
    height: 86px;
}

.btn-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 46px;
    border-radius: 50%;
    cursor: pointer;
    border: 0;
    background-color: transparent;
}


/* .btn-icon:hover {
    background-color: #e2e2e8;
} */

.custom-switch {
    height: 46px;
    display: flex;
    align-items: center;
}

.form-label {
    font-size: 0.75rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    margin-bottom: 0px;
    color: #535963;
}

.custom-collapse {
    overflow: hidden;
    transition-duration: 0.6s;
    transition-timing-function: ease;
}

.custom-collapse:not(.show) {
    height: 0px !important;
}

.form-control-no-validate {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.btn-link {
    color: #007bff;
    text-decoration: none;
    border: none;
    background-color: transparent;
    /* text-overflow: ellipsis; */
    /* overflow: hidden; */
    /* white-space: nowrap; */
    width: 100%;
}

.btn-link:hover {
    color: #0056b3;
    text-decoration: none;
}

.hide {
    display: none;
}

.standard-prepend-append {
    background-color: #e2e2e8;
    color: #495057;
    border-color: #ced4da;
}

input[type=number] {
    text-align: right;
}

.align-right {
    text-align: right;
}

.data-loading {
    animation-name: loading;
    animation-duration: 1.6s;
    animation-iteration-count: infinite;
}

@keyframes loading {
    0% {
        filter: brightness(1);
    }

    50% {
        filter: brightness(0.9);
    }
}

.modal-full {
    max-width: 100%;
    width: 100%;
    max-height: 100%;
    height: 100%;
    margin: 0;
}

.modal-full .modal-content {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 0;
}

.modal-working-logs {
    padding: 4px 0;
    display: flex;
    align-items: center;
}

.modal-working-logs .status-icon {
    padding: 0 4px;
}

.modal-working-logs .message-label {
    padding: 0 4px;
}

.modal-working-logs .status-icon .success {
    color: #00ab00;
}

.modal-working-logs .status-icon .failure {
    color: #e40000;
}

.modal-dialog.zero-padding .modal-body {
    padding: 0;
}

.modal-dialog .modal-body .modal-body-action {
    transition-duration: 0.4s;
    background-color: green;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 3;
}

.modal-dialog .modal-body .body-action-container {
    padding-right: 30px;
    position: relative;
}

.modal-dialog .modal-body .body-action-bar {
    height: 0px;
    transition-duration: 0.4s;
    overflow: hidden;
}

.modal-dialog .modal-body .body-action-bar-toggle {
    position: absolute;
    top: 0;
    right: -6px;
    height: 30px;
    width: 30px;
    z-index: 2;
    transition-duration: 0.4s;
    transform: translateY(-30px);
}

.modal-dialog .modal-body .body-action-bar-toggle .toggle-icon {
    height: 30px;
    font-size: 30px;
    border-radius: 50%;
}

.modal-dialog .modal-body .body-action-bar-toggle .toggle-icon:hover {
    background-color: #eeeeee;
}

.modal-dialog .modal-footer > a:hover > span {
  color: white;
}

.modal-dialog .modal-footer button + button {
  font-size: 0.875rem;
  padding: 6px 16px;
  margin-left: 8px;
}

.modal-dialog form .form-group {
    height: 96px;
    /* height: 84px; */
    /* margin-bottom: 10px; */
}

.modal-dialog form .form-label {
    margin-bottom: 0;
}

.modal-dialog form .form-control {
    min-height: 46px;
}

.modal-dialog form .form-control-file .custom-file {
    height: 46px;
    min-height: 46px;
}

.modal-dialog form .form-control-file .custom-file-label::after,
.modal-dialog form .form-control-file .custom-file-label,
.modal-dialog form .form-control-file .custom-file-input {
    height: 100%;
    display: flex;
    align-items: center;
}

.css-kp9ftd-MuiIcon-root {
    font-size: 1.7em;
}

.clickable {
    cursor: pointer;
}

.otp_dialog > .MuiDialog-container > .MuiPaper-root {
    padding: 10px 30px 10px 30px;
}

.otp_dialog_actions{
    display: grid;
    justify-content: center;
    align-items: center;
    row-gap: 1rem;
    margin-top: 1rem;
}

.otp_dialog_content {
    display: grid;
    row-gap: 1rem;
}

.otp_dialog_content p{
  margin-left: 1em;
  text-indent: -1em;
  white-space: pre-wrap;
}

.otp_dialog_dl {
  display: flex;
  justify-content: space-between;
}

.otp_dialog_dl_batch {
  width: 35%;
  margin-left: 1em;
}

.otp_dialog_dl_ms-auth{
  width: 164px;
}

.otp_dialog_dl_ms-auth_qr{
  width: 90px;
  margin: 0 auto 15px;
}
.otp_dialog_dl_ms-auth_caption{
  font-size: 0.75rem;
  font-weight: bold;
  background-color: #E8E8E8;
  text-align: center;
  border-radius: 999px;
  padding: 3px 0;
  color: black;
}
.otp_dialog_button_store{
    display: grid;
    grid-template-columns: repeat(6, minmax(0, 1fr));
}

.otp_dialog_button_store_grid{
    display: grid;
    grid-column: 1 / span 2;
}
.otp_dialog_button_store_guide{
    grid-column: 1 / span 4;
}
.otp_dialog_button_store_qr{
    grid-column: span 2 / span 2;
}
.logo_googplay{
    cursor: pointer;
}
.logo_applestore{
    cursor: pointer;
    height: 75px;
}

.mfa_loading_full{
    min-height: 211px;
}

.guest_settings_layout{    
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.guest_settings_layout > .checkbox_guest {
   margin-top: 10px;
}
.guest_settings_layout > h2 {
   margin-right: 4rem !important;
}

.mfa_loading_full{
    min-height: 211px;
}