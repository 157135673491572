body {
  overflow-x: visible !important;
  overflow: hidden;
  /* min-height: 100%; */
}

body>iframe {
  display: none;
}

img {
  width: 100%;
}

.layout {
  display: grid;
  grid-template-columns: 256px auto;
  min-height: 100vh;
  height: 100vh;
  /* min-width: 960px; */
  display: table;
  width: 100%;
}

.layout.no-menu {
  display: flex;
}

.layout .layout-left {
  min-width: 256px;
  width: 256px;
  max-height: 100vh;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  display: table-cell;
  vertical-align: top;
}

.layout .layout-right {
  width: auto;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: space-between;
  vertical-align: top;
  display: table-cell;
  vertical-align: top;
  height: 100%;
}

.layout.no-menu .layout-right {
  width: 100%;
}

.layout.no-menu .layout-right .layout-right-top {
  width: 100%;
}


/******************************/

.layout .layout-left .left-fixed-box {
  height: 100vh;
  width: 256px;
  border-right: 1px solid #e2e2e8;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.left-head-box {
  position: absolute;
  margin-top: 60px;
}

.layout .layout-left>.container {
  position: absolute;
}

.layout .layout-left .menu-container {
  min-height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  overflow: auto;
  justify-content: space-between;
}

.layout .layout-left .menu-container .menu-box {
  padding: 20px 15px 5px;
}

.layout .layout-left .menu-bottom-container {
  padding: 20px 20px 20px;
}

.layout .layout-left .menu-bottom-container .menu-bottom {
  font-weight: 400;
  color: #626669;
  font-size: 12px;
}

.layout .layout-left .menu-bottom-container .menu-bottom .info-context {
  display: flex;
  align-items: center;
}

.layout .layout-left .logo-horizontal {
  height: 60px;
  width: 100%;
  top: 0;
  left: 0;
  min-height: 60px;
  border-bottom: 1px solid #e2e2e8;
  box-sizing: border-box;
  background-color: #fff;
}

.layout .layout-left .logo-horizontal .logo-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px 10px 0px 0px;
}

.layout .layout-left .logo-horizontal .logo-wrapper:hover {
  background: #f2f2f8;
}

.logo-horizontal .logo-wrapper img {
  width: 60px;
}

.copylight {
  margin-top: 5px;
}

.footerInfo a {
  display: inline-block;
  padding: 2px 5px;
}

.layout .layout-right .right-body {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

@media screen and (max-width: 1260px) {
  .layout .layout-right .right-body {
    overflow-x: auto;
  }
}

.layout .layout-right-top+.right-body {
  height: calc(100% - 60px);
}

.layout .layout-right-top {
  height: 60px;
  width: 100%;
  min-height: 60px;
  border-bottom: 1px solid #e2e2e8;
  background: #ffffff;
  box-sizing: border-box;
  padding: 10px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.layout .layout-right-top .icon {
  min-width: 30px;
}

.layout .layout-right-top .icon>div {
  padding-top: 4px;
  padding-bottom: 4px;
}

.layout .layout-right-top .icon>span {
  padding: 0px 16px;
  line-height: 40px;
}

.tenant-switch {
  font-size: 0.81rem;
}

.layout .layout-right-top .profile-icon,
.layout .signer-list-wrapper .user-info .display-avatar {
  min-width: 30px;
  min-height: 30px;
  width: 30px;
  height: 30px;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin: 0 5px;
  overflow: hidden;
  border-radius: 15px;
}

.layout .signer-list-wrapper .user-info .display-avatar {
  min-width: 34px;
  min-height: 34px;
  width: 34px;
  height: 34px;
  border-radius: 17px;
  margin: 0;
}

.layout .layout-right-top .profile-icon div,
.layout .signer-list-wrapper .user-info .display-avatar div {
  background: #d8d9df;
}

.layout .layout-right-top .profile-icon svg,
.layout .signer-list-wrapper .user-info .display-avatar svg {
  color: #7b7ea6;
  width: 18px;
}

.layout .layout-right-top .top-right {
  display: flex;
  justify-content: space-between;
}

.layout .layout-right-top .profile-menu {
  border-radius: 20px;
}

.layout .layout-right-top .profile {
  display: flex;
  border-radius: 20px;
  padding: 4px 16px 4px 0px;
  border: 1px solid transparent;
  text-decoration: none;
  min-width: 200px;
  background: #f2f2f8;
  justify-content: flex-start;
}

.layout .layout-right-top .profile .profile-icon span {
  font-size: 34px;
  line-height: 30px;
  text-indent: -2px;
  color: #a2a6a9;
}

.layout .layout-right-top .profile-menu .MuiMenu-List {
  position: absolute;
  width: 100%;
  margin-top: 0px;
  border: 1px solid #e2e2e8;
  border-top: none;
  border-radius: 0;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background: #f2f2f8;
}

.error-tr {
  background: #f2f2f8;
}

.error {
    height: 100vh;
}

.error .errorPageElements {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.layout .layout-right-top .show .profile {
  border-color: #f2f2f8;
  border-bottom-color: transparent;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.layout .layout-right-top .profile-menu .dropdown-item:hover {
  background: #e2e2e8;
}

.layout .layout-right-top .profile-info span {
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  max-width: 180px;
  white-space: nowrap;
  text-align: left;
}

.layout .layout-right-top .profile-info .info-context {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.layout .layout-right-top .profile-info .name {
  font-size: 14px;
  line-height: 16px;
  height: 16px;
  font-weight: 500;
  color: #062b60;
}

.layout .layout-right-top .profile-info .tenant {
  font-size: 12px;
  height: 14px;
  line-height: 14px;
  font-weight: 500;
  color: #9398ab;
}

.layout .layout-right-top .system-notification {
  color: red;
}

.layout .layout-right .right-body .right-body-container {
  min-height: 100%;
  min-width: 100%;
  width: 100%;
  height: 100%;
  position: relative;
}

.layout .layout-right .right-bottom .licensce-info {
  font-weight: 400;
  color: rgb(147, 152, 171);
  font-size: 12px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  align-items: flex-end;
}

.layout .layout-left .menu-container .menu-custom {
  padding: 0;
}

.layout .layout-left .menu-container .menu-custom li {
  cursor: pointer;
  padding: 0;
  margin-bottom: 13px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.layout .layout-left .menu-container .menu-custom li .document-count {
  position: absolute;
  right: 15px;
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.layout .layout-left .menu-container .menu-custom li .document-count span {
  display: block;
  aspect-ratio: 1/1;
  width: 100%;
  height: 100%;
  text-align: right;
  line-height: 22px;
  font-size: 0.8rem;
  font-weight: 500;
}

.layout .layout-left .menu-container .menu-custom li .document-count span.is-zero {
  display: none;
}

.layout .layout-left .menu-container .menu-custom li .avatar-custom {
  display: flex;
  align-items: center;
}

.layout .layout-left .menu-container .menu-custom li:not(.active) .avatar-custom {
  color: #062b60;
}

.layout .layout-left .menu-container .menu-custom li .avatar-custom .icon-avatar,
.layout .layout-left .menu-container .menu-custom li .avatar-custom .text-avatar {
  height: 48px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.layout .layout-left .menu-container .menu-custom li .avatar-custom .icon-avatar svg {
  height: 24px;
  width: 24px;
  /* filter: brightness(3) grayscale(1); */
}

.layout .layout-left .menu-container .menu-custom li.active .avatar-custom .icon-avatar svg {
  filter: none;
}

.layout .layout-left .menu-container .menu-custom li .avatar-custom .text-avatar span {
  font-size: 1rem;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  padding-left: 12px;
}

.layout .layout-left .menu-container .menu-custom li a {
  display: block;
  width: 100%;
  height: 40px;
  border-radius: 999px;
  background-color: #ffffff;
  padding: 0 13px;
}

.layout .layout-left .menu-container .menu-custom li a:hover {
  background-color: #f8f8fc;
}

.layout .layout-left .menu-container .menu-custom li.active a {
  background-color: #f2f2f8;
}

.layout .layout-left .menu-container .menu-custom li.active a {
  font-weight: 500;
  color: #1069d5;
}

.layout .layout-left .menu-container .step-custom li {
  padding: 8px 10px;
  margin: 6px 0;
  display: flex;
  align-items: center;
}

.layout .layout-left .menu-hidden {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: -256px;
  transition-duration: 0.4s;
  transition-timing-function: ease;
  background-color: #fff;
  /* border-right: 1px solid #cdcfd9; */
  /* box-shadow: 0px 0px 100px inset #000000bf; */
}

.layout .layout-left .menu-hidden.active {
  left: -1px;
}

.layout .container-fluid {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.layout .layout-right-title h2 {
  font-size: 18px;
  line-height: 24px;
  color: inherit;
  margin: 40px 0;
}

.layout .title h2 {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  padding: 18px 0 20px;
}

.layout .page-settings .title h2 {
  margin: 15px 0 0 0;
  padding: 0;
}

.layout .tenant-info {
  border: 1px solid #e2e2e8;
  border-radius: 6px;
  background-color: #f2f2f8;
}

.layout .tenant-info-item {
  border-bottom: solid 1px #e2e2e8;
  padding: 16px;
  display: flex;
  align-items: center;
}

.layout .tenant-info-item:last-child {
  border-bottom: none;
  border-radius: 0 0 6px 6px;
}

.layout .tenant-info-item .label {
  margin: 0;
  white-space: nowrap;
}

.layout .tenant-info-item .value .label {
  margin-right: 20px;
}

.layout .tenant-info-item .label .icon {
  width: 24px;
  height: 24px;
}

.layout .tenant-info-item>.label {
  display: inline-block;
  width: 180px;
  font-size: 0.81rem;
  color: #535963;
  font-weight: 400;
}

.layout .tenant-info-item .value {
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
}

.layout .tenant-info-item div {
  margin-right: 20px;
}

.layout .tenant-info-item div span {
  padding: 0 5px 0 0;
}

.text-unit {
  font-size: .8em;
}

.layout .tenant-info-item .tenant-info-item-small {
  font-size: .9em;
}

.layout .tenant-info-item .services-label {
  white-space: nowrap;
}

.layout .tenant-info-item .services-label .icon {
  width: 24px;
  height: 24px;
}

.layout .tenant-info-item .services-label p.label {
  margin-right: 8px;
}

.layout .tenant-info-item .services-label p.label:not(:last-child)::after {
  content: ",";
}

.layout .document-info .list-stat-item {
  display: inline-block;
  width: 150px;
  border-right: solid 1px #e2e2e8;
  text-align: right;
  padding: 10px 15px 10px 0;
  margin-bottom: 20px;
}

.layout .document-info .list-stat-item p {
  margin-bottom: 0;
  color: #4C4F5E;
}

.layout .document-info .list-stat-item p.label {
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.layout .document-info .list-stat-item p.label svg {
  width: 24px;
  height: 24px;
}

.layout .document-info .list-stat-item p.label span {
  padding: 0 0 0 6px;
}

.layout .document-info .list-stat-item p.value {
  font-size: 20px;
  line-height: 28px;
  margin-top: 5px;
}

.layout .header-wrapper {
  top: 0;
  height: 60px;
  padding: 6px 15px 6px 5px;
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  z-index: 1;
}

.layout .pdf-full-preview-wrapper.active .header-wrapper {
  top: -60px;
}

.layout .header-wrapper.sticky {
  position: sticky;
  border-bottom: 1px solid #e2e2e8;
  z-index: 11;
}

.layout .header-wrapper .header-left {
  display: flex;
}

.layout .header-wrapper .header-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.layout .header-wrapper .header-right,
.layout .header-wrapper .header-left {
  display: flex;
  align-items: center;
  font-size: 14px;
  flex-shrink: 0;
}

.layout .document-detail.active .pdf-full-preview-wrapper .header-wrapper {
  padding-right: 15px;
}


/* .layout .document-detail.active .header-wrapper .header-right div {
    margin-right: 0px;
} */

.layout .header-wrapper .document-title {
  font-size: 1.1rem;
  line-height: 45px;
  font-weight: 500;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: left;
  display: block;
  padding: 0 20px;
}

.layout .btn.btn-custom {
  box-shadow: 0 1px 6px rgb(0 0 0 / 26%);
  border-radius: 100px;
  padding: 6px 10px;
}

.layout .btn-custom-verification {
  box-shadow: 0 1px 6px rgb(0 0 0 / 26%);
  border-radius: 100px;
  padding: 6px 10px;
}

.layout .btn.btn-custom span {
  left: 8px;
}

.layout .btn-custom .label {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
}

.layout .header-wrapper .pagination {
  display: flex;
  align-items: center;
}

.layout .body-wrapper .item-row {
  padding: 0;
}

.layout .btn-pagination {
  background: transparent;
  border-radius: 22px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  border: none;
}

.layout .btn-pagination:hover {
  background: #f2f2f8;
}

.step-custom .step-child {
  position: relative;
  display: flex;
  align-items: center;
  color: #cecfd9;
}

.step-custom .step-child .step-circle {
  position: relative;
  min-width: 15px;
  height: 15px;
  border-radius: 50px;
  border: 2px solid #dadada;
  margin-right: 16px;
  background-color: #ffffff;
}

.step-custom.step-vertical .step-child .step-access-ramp {
  border-left: 3px solid #cecfd9;
  position: absolute;
  height: 40px;
  top: 13px;
  left: 4px;
}

.step-custom.step-vertical .step-child:last-child .step-access-ramp {
  display: none;
}

.step-custom .step-child .step-circle .step-circle-dot {
  background-color: transparent;
  width: 7px;
  height: 7px;
  border-radius: 50px;
  position: absolute;
  left: 2px;
  top: 2px;
}

.step-custom .step-child:not(.disabled) {
  color: #3697e1;
}

.step-custom .step-child.active {
  font-weight: bold;
}

.step-custom .step-child:not(.active):not(.disabled) {
  cursor: pointer;
}

.step-custom .step-child.disabled {
  cursor: not-allowed;
}

.step-custom .step-child:not(.disabled) .step-circle {
  border-color: #3697e1;
  background-color: #ffffff;
}

.step-custom .step-child.pass .step-circle .step-circle-dot {
  background-color: #3697e1;
}

.step-custom .step-child.active .step-circle .step-circle-dot {
  background-color: #ff69b4;
}

.step-custom .step-child .step-label {
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
}

.step-custom.step-vertical .step-child.pass .step-access-ramp {
  border-color: #3697e1;
}

.layout .layout-right .right-body .page-action-bottom {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-around;
  margin-bottom: 40px;
}

.layout .layout-right .right-body .page-action-bottom-relative {
  display: flex;
  justify-content: space-around;
  padding: 40px;
}

.layout .layout-right .right-body .page-action-bottom-absolute {
  padding: 40px;
  position: absolute;
  bottom: 18px;
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.invalid-feedback {
  font-size: 0.71rem;
}

.form-group {
  margin-bottom: .7rem;
}

.layout .btn:not(.input-group-child) {
  position: relative;
  min-width: 120px;
  height: 46px;
  font-size: 15px;
  font-weight: 500;
  margin: 0;
  margin-right: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 10px;
}

.layout .btn:not(.input-group-child):last-child {
  margin-right: 0;
}

.layout .btn-custom-group {
  box-shadow: 0 1px 6px rgb(0 0 0 / 26%);
  border-radius: 100px;
  height: 46px;
  margin-right: 8px;
}

.layout .btn-custom-mui {
  height: unset;
  margin-left: 8px;
  margin-right: 0;
}

.layout .btn-custom-mui .btn {
  height: unset;
  background-color: #1976d2;
  border-left: none;
  border-right: none;
}

.layout .btn-custom-mui .btn:hover,
.layout .btn-custom-group.btn-custom-mui .dropdown-item:hover {
  background-color: #0a63bc;
  box-shadow: 0px 2px 4px -1px #00000033, 0px 4px 5px 0px #00000024, 0px 1px 10px 0px #0000001f;
}

.layout .btn-custom-mui.btn-custom-group>.btn {
  padding: 6px 10px 6px 16px;
  min-width: unset;
  white-space: nowrap;
}

.layout .btn-custom-mui.btn-custom-group>.dropdown-toggle {
  padding: 6px 16px 6px 10px;
}

.layout .btn-custom-group>.btn {
  border-radius: 100px;
  padding: 6px 10px 6px 20px;
  margin: 0;
  min-width: 110px;
}

.layout .btn-custom-group .dropdown-toggle {
  min-width: unset;
  padding: 6px 20px 6px 6px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.layout .btn-custom-group .dropdown-menu {
  background-color: transparent;
  border: none;
  transform: translate(0px, 52px) !important;
  padding: 0;
}

.layout .btn-custom-group .dropdown-item {
  margin-bottom: 6px;
  background-color: #fff;
  height: 46px;
  border: 1px solid #ddd;
}

.layout .btn-custom-group .dropdown-item.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.layout .btn-custom-group.btn-custom-mui .dropdown-item {
  height: unset;
  padding: 6px 16px;
  background-color: #1976d2;
}

.layout .btn.input-group-child {
  min-width: 100px;
  height: 46px;
  font-size: 14px;
  padding: 6px 4px;
  background-color: #f2f2f8;
  border-color: #ced4da;
  color: #007bff;
}

.layout .btn.btn-form-control {
  height: auto;
  min-height: 46px;
  padding: 4px;
  word-break: break-all;
}

.layout .btn-label {
  width: 100%;
  margin: 0 10px;
}

.layout .layout-right .right-body .document-detail-container,
.layout .layout-right .right-body .documentprepare-container,
.layout .layout-right .right-body .determine-container {
  position: relative;
  min-height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.layout .layout-right .right-body .document-detail-container .document-detail-form,
.layout .layout-right .right-body .determine-container .determine-form {
  padding-top: 15px;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%;
}

.layout .layout-right .right-body .document-detail-container .document-detail-form .custom-form-outline.form-validated {
  margin-bottom: 20px;
}

.layout .layout-right .right-body .document-detail-container .document-detail-form .custom-form-outline.form-validated .invalid-feedback {
  margin-top: 0;
  height: 20px;
}

.layout .layout-right .right-body .determine-container .determine-form .tab-content {
  padding: 15px;
}

.full-preview {
  height: 100%;
  width: 100%;
  position: relative;
}

.full-preview .control-preview {
  background-color: #474e63;
  box-shadow: inset 0 0 15px rgb(52 59 69 / 80%);
  display: flex;
  flex-direction: column;
  align-items: baseline;
  overflow: auto;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 0;
}

.full-preview .control-preview .react-pdf__Document {
  min-width: 100%;
  min-height: 100%;
}

.full-preview .control-preview .react-pdf__Document .react-pdf__message {
  height: 100%;
}

.full-preview .control-preview .react-pdf__Document .react-pdf__message .pdf-loading {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.full-preview .control-preview .react-pdf__Document .react-pdf__message .pdf-loading .img-container {
  width: 80%;
  height: 150px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  box-shadow: 2px 2px 2px #424242;
}

.full-preview .control-preview .pdf-page-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.full-preview .pdf-custom-page {
  display: flex;
  justify-content: center;
}

.full-preview canvas {
  margin: 0;
  box-shadow: 4px 4px 4px #444444;
}

.layout .layout-right .right-body .control-container {
  position: absolute;
  height: 100%;
  width: 100%;
  pointer-events: none;
  z-index: 10;
}

.layout .layout-right .right-body .control-container .imprint-stamp {
  top: 20px;
  left: 20px;
  width: 150px;
  background: #021a45c9;
  position: absolute;
  box-shadow: 0 2px 6px #00000029;
  border-radius: 6px;
  padding: 20px 10px 10px;
  user-select: none;
  max-height: 100%;
  overflow: auto;
  cursor: move;
}

.layout .layout-right .right-body .control-container .imprint-stamp .imprint-container {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.layout .layout-right .right-body .control-container .imprint-stamp .imprint-operator {
  margin-top: 10px;
}

.layout .layout-right .right-body .control-container .imprint-stamp .imprint-operator button {
  margin-top: 5px;
  width: 100%;
  min-width: 100%;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.layout .layout-right .right-body .control-container .imprint-stamp .imprint-operator button:last-child {
  background: #dfe5f4;
}

.layout .layout-right .right-body .control-container .imprint-stamp .imprint-operator button:last-child:hover {
  background: #cdd4e4;
}

.layout .layout-right .right-body .control-container .imprint-stamp .imprint-block {
  min-height: 40px;
  min-width: 100%;
  display: flex;
  align-items: center;
  color: #FFFFFF;
  font-size: 11px;
  border: 1px dashed #ffffff80;
  padding: 6px;
  cursor: move;
  margin: 4px;
}

.layout .layout-right .right-body .control-container .imprint-stamp .imprint-block.hide {
  display: none;
}

.layout .layout-right .right-body .control-container .imprint-stamp .imprint-block.ondrag {
  position: absolute;
  margin-top: 0px;
  background-color: #1f3153;
  box-shadow: -2px 2px 2px #939393;
}

.layout .layout-right .right-body .control-container .imprint-stamp .imprint-block .label-text {
  font-size: 11px;
  margin-left: 10px;
  text-transform: capitalize;
}

.layout .layout-right .right-body .control-preview .imprint-block {
  border: 2px solid #3697E1;
  background: #FFFFFFCC;
  cursor: move;
  z-index: 2;
  font-size: 11px;
  position: absolute;
  overflow: hidden;
}

.layout .layout-right .right-body .control-preview .imprint-block.group-guests {
  border: 2px solid #f15050;
}

.layout .layout-right .right-body .control-preview .imprint-block.type-text {
  width: 240px;
  height: 46px;
  border-radius: 4px;
}

.layout .layout-right .right-body .control-preview .imprint-block.type-check {
  width: 25px;
  height: 25px;
}

.layout .layout-right .right-body .control-preview .imprint-block.type-signature {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.layout .layout-right .right-body .control-preview .imprint-block.not-allowed {
  cursor: not-allowed;
}

.layout .layout-right .right-body .control-preview .imprint-block svg {
  display: none;
}

.layout .layout-right .right-body .control-preview .imprint-block .label-text {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  white-space: normal;
}

.layout .layout-right .right-body .control-container .page-index-info .info-label,
.control-container .page-index-info .info-label,
.layout .layout-right .right-body .control-container .btn,
.control-container .btn,
.layout .layout-right .right-body .control-preview .imprint-block,
.layout .layout-right .right-body .control-container .imprint-stamp,
.layout .layout-right .right-body .control-container button {
  pointer-events: all;
}

.layout .layout-right .right-body .control-container .view-size-controller,
.control-container .view-size-controller {
  position: absolute;
  display: flex;
  width: 90px;
  justify-content: space-between;
  right: 24px;
  top: 10px;
}

.layout .layout-right .right-body .imprint-block.group-guests svg {
  fill: #f15050
}

.layout .layout-right .right-body .control-container .page-index-info,
.control-container .page-index-info {
  position: absolute;
  width: 100%;
  top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.layout .layout-right .right-body .control-container .page-index-info .info-label,
.control-container .page-index-info .info-label {
  background-color: #1f232d;
  border-radius: 4px;
  padding: 4px 10px 5px;
  color: #e3e3fc;
  opacity: .85;
  font-size: .75rem;
  line-height: .85rem;
}

.layout .layout-right .right-body .control-container .btn.size-decrease,
.control-container .btn.size-decrease,
.layout .layout-right .right-body .control-container .btn.size-increase,
.control-container .btn.size-increase,
.pdf-fragment .pdf-file-info .btn-info {
  border-radius: 22px;
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  cursor: pointer;
  background-color: #5b647e;
  box-shadow: 0 1px 5px rgba(44, 50, 59, .85);
  color: #d5d5e6;
}

.layout .layout-right .right-body .control-container .btn.size-decrease:hover,
.layout .layout-right .right-body .control-container .btn.size-increase:hover,
.pdf-fragment .pdf-file-info .btn-info:hover {
  background-color: #616a85;
  box-shadow: 0 3px 10px rgb(44, 50, 59);
  color: #d7d7e8;
}

.layout .layout-right .right-body .file-panel {
  display: flex;
  flex-direction: column;
  min-width: 240px;
}

.layout .layout-right .right-body .file-panel.upload {
  width: 200px;
}

.layout .layout-right .right-body .file-panel.preview {
  min-width: 280px;
  position: relative;
}

.layout .layout-right .right-body .file-panel .file-drop-zone {
  padding-top: 50px;
}

.layout .layout-right .right-body .file-panel .file-preview-zone {
  position: relative;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #e2e2e8;
  align-items: center;
  justify-content: flex-start;
}

.layout .layout-right .right-body .file-panel .file-preview-zone .pdf-custom-page {
  display: none;
  position: relative;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.layout .layout-right .right-body .file-panel .file-preview-zone .pdf-custom-page.active {
  display: flex;
  /* max-width: 240px; */
  /* max-height: 340px; */
}

.layout .layout-right .right-body .file-panel .file-preview-zone .pdf-custom-page.active canvas {
  box-shadow: 0 0 13px rgba(101, 111, 127, .3);
}

.layout .layout-right .right-body .file-panel .file-preview-zone .page-navigator {
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.layout .layout-right .right-body .file-panel .file-preview-zone .page-navigator .btn-icon {
  width: 26px;
  height: 26px;
  color: #484e52;
}

.layout .layout-right .right-body .file-panel .file-preview-zone .page-navigator .info-label {
  padding: 0 10px;
  font-size: 0.75rem;
  color: #484e52;
}

.nav-tabs .nav-link {
  height: 40px;
  line-height: 40px;
  text-align: center;
  padding: 0 1rem;
}

.layout .layout-right .right-body .file-panel .file-drop-zone .file-dropbox,
.pdf-fragment .file-upload-panel .dropbox-area {
  height: 140px;
  width: 110px;
  margin: 0;
  cursor: pointer;
  border: 1px dashed #7a85a3;
  background-color: #656f8a;
  background-repeat: no-repeat;
  background-position: center;
}

.layout .layout-right .right-body .file-panel .file-drop-zone.nofile {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
  height: 100%;
}

.layout .layout-right .right-body .file-panel .file-drop-zone.nofile .file-dropbox,
.pdf-fragment .file-upload-panel .dropbox-area {
  height: 297px;
  width: 210px;
  background-size: 100px;
  transition: all .2s;
}

.layout .layout-right .right-body .file-panel .file-upload-list {
  height: 100%;
  padding: 15px 10px;
}

.layout .layout-right .right-body .file-panel .file-upload-list ul {
  padding: 0;
  margin: 0;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.layout .layout-right .right-body .file-panel .file-upload-list .file-item {
  font-size: 14px;
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 34px;
  color: inherit;
  cursor: pointer;
  font-weight: 400;
  padding: 0 0 0 13px;
  border-radius: 17px;
}

.layout .layout-right .right-body .file-panel .file-upload-list .file-item:not(.active):hover {
  background: #f2f2f8;
}

.layout .layout-right .right-body .file-panel .file-upload-list .file-item.active {
  cursor: unset;
  font-weight: 400;
  color: #1069d5;
  font-weight: 500;
}

.layout .layout-right .right-body .file-panel .file-upload-list .file-name {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.layout .layout-right .right-body .file-panel .file-upload-list .file-remove {
  margin: 0;
  color: #ff762c;
  width: 36px;
  height: 36px;
  padding: 10px;
  font-size: 16px;
  line-height: 16px;
}

.layout .layout-right .right-body .file-panel .file-upload-list .file-remove:hover {
  background-color: #41485c;
}

.layout .layout-right .right-body .file-panel .file-upload-list .file-form-validate {
  display: none;
}

.layout .layout-right .right-body .file-panel .file-upload-list .file-form-validate-fail {
  margin: 0 10px;
  color: #f15050;
  padding: 8px;
  font-size: 34px;
}

.layout .layout-right .right-body .file-panel .file-upload-list .file-form-validate-success {
  margin: 0 10px;
  color: #28a745;
  padding: 8px;
  font-size: 34px;
}

.layout .layout-right .right-body .file-panel .file-attachments {
  height: auto;
}

.layout .layout-right .right-body .file-panel .file-dropbox:hover {
  border-color: #40a9ff;
}

.layout .layout-right .right-body .file-panel .info,
.pdf-fragment .no-file .file-upload-panel label.info {
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 14px;
  margin: 10px;
  color: #d5d5e6;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.layout input:required,
.layout select:required {
  border-color: #da6517;
}

.layout input.is-valid,
.was-validated .form-control:valid,
.layout select.is-valid {
  border-color: #28a745;
}

.layout input.is-invalid,
.was-validated .form-control:invalid,
.layout select.is-invalid {
  border-color: #dc3545 !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") !important;
}

.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, .25) !important;
}

.layout .layout-right .right-body .file-panel .file-dropbox input[type=file] {
  display: none;
}

.layout .attributes-wrapper .form-group {
  height: 60px;
}

.layout .attributes-wrapper .btn-icon.remove {
  width: 46px;
  height: 46px;
}

.layout .attributes-wrapper .input-group .form-control {
  border-radius: .25rem;
}

.layout .host-setting-container {
  display: flex;
  height: 100%;
}

.layout .host-setting-container .user-list {
  width: 640px;
  border-left: 1px solid #e2e2e8;
}

.layout .full-form {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.layout .full-form .form-body {
  margin: 0 auto;
  width: 100%;
  height: 100%;
}

.layout #form_Search.full-form .form-body {
  margin: 0 auto;
  width: 100%;
  height: unset;
}

.layout .documentinfo-container .full-form .form-body {
  max-width: 700px;
}

.layout .signer-list-wrapper {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
}

.layout .determine-container .signer-list-wrapper {
  flex-direction: row;
}

.layout .determine-container .signer-list-wrapper .form-group {
  height: 46px;
  display: flex;
}

.layout .determine-container .doc-info .form-group {
  height: 46px;
}

.layout .determine-container .doc-info .info-label {
  display: flex;
  align-items: center;
}

.user-selection-container .form-label,
.signer-list-wrapper .form-label {
  margin-bottom: 5px;
}

.search-input-container {
  display: flex;
  width: 100%;
  justify-content: center;
  max-height: 40px;
  border: 0;
}

.search-input-field {
  width: 95%;
  background-color: rgb(239 238 246);
  border: 0px;
  border-radius: 5px;
}

.search-round-container {
  display: flex;
  width: 75%;
}

.search-round {
  background-color: rgba(242, 242, 247, 255);
  width: 80%;
  outline: none;
}

.search-round:hover {
  outline: none;
}

.layout .signer-list-wrapper .user-info {
  margin: 0;
  padding: 6px;
  width: 100%;
  min-width: 100%;
  height: 46px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  background-color: #f2f2f8;
  justify-content: space-between;
  margin: 0 0 10px 0;
  /* box-shadow: 1px 1px 2px #888; */
}

.layout .signer-list-wrapper .user-info.user1 {
  background-color: #e1edff;
}

.layout .signer-list-wrapper .user-info.user1 .display-avatar div {
  background-color: #bad1f5;
}

.layout .signer-list-wrapper .user-info .display-avatar svg {
  width: 20px;
}

.layout .signer-list-wrapper .user-info.empty {
  background-color: unset;
}

.layout .signer-list-wrapper .user-info .user-info-detail {
  width: 100%;
  padding: 0 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.layout .signer-list-wrapper .user-info .top-info {
  line-height: 1.1rem;
  font-size: 0.875rem;
  font-weight: 500;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.layout .signer-list-wrapper .user-info .bottom-info {
  line-height: 1.1rem;
  font-size: 0.81rem;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: #626669;
}

.layout .signer-list-wrapper .viewer-info {
  float: left;
  display: flex;
  padding: 0 10px;
  margin-right: 8px;
  margin-bottom: 8px;
  border-radius: 12px;
  height: 24px;
  font-size: .81rem;
  align-items: center;
  background-color: #f2f2f8;
}

.layout .signer-list-wrapper .viewer-info.group {
  background-color: #e2e2e8;
}

.layout .user-selection-container .user-info .user-info-optional,
.layout .signer-list-wrapper .user-info .user-info-optional {
  width: 40px;
  min-width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  right: 0;
  top: 0;
  height: 100%;
}

.layout .signer-list-wrapper .user-info .user-info-optional .custom-switch {
  width: 100%;
  padding-left: 44px;
}

.layout .signer-list-wrapper .user-info .btn-icon {
  color: red;
  height: 30px;
  width: 30px;
  margin-right: 6px;
}

.layout .signer-list-wrapper .user-info .btn-icon:hover {
  background-color: #cecece;
}

.layout .signer-list-wrapper .user-info .btn-icon svg {
  fill: red;
}

.layout .flex-column {
  display: flex;
  flex-direction: column;
  position: relative;
}

.layout .flex-right {
  display: flex;
  justify-content: flex-end;
}

.layout .flex-full {
  height: 100%;
  width: 100%;
  margin: 0;
}

.layout .user-selection-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.layout .user-selection-container .nav-tabs {
    border-bottom: none;
}

.layout .user-selection-container .tab-content,
.layout .user-selection-container .tab-content .tab-pane.active,
.layout .user-selection-container .input-group {
  height: 100%;
}



/* .layout .user-selection-container .input-group{
    background: #f2f2f8;
} */

.layout .user-selection-box {
  overflow: auto;
  position: absolute;
  width: calc(100% - 30px);
  height: calc(100% - 55px);
  top: 50px;
  left: 15px;
  padding: 0 0 10px 0;
}

.layout .user-selection-box .user-info {
  display: flex;
  padding: 0;
  margin: 10px 10px 0;
  border: none;
  border-radius: 0;
  font-size: .875rem;
  background: inherit;
  color: #062b60;
  white-space: nowrap;
  min-width: 300px;
}

.layout .user-selection-box .user-info>.user-info-detail {
  text-overflow: ellipsis;
  overflow: hidden;
}

.layout .user-selection-box .user-info .top-info {
  font-weight: 500;
}

.layout .user-selection-box .user-info .bottom-info {
  font-size: .81rem;
  color: #535963;
}

.layout .user-selection-box .user-info ul li {
  margin: 7px 0 0;
}

.layout .user-selection-box .collapse {
  padding-right: 2px;
}

.layout .user-selection-box .user-info .action {
  width: 34px;
  height: 46px;
}

.layout .user-selection-box .user-info .action button {
  padding: 0;
  height: 100%;
  width: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1069D5;
  background-color: #d4d4d4;
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25rem;
  /* box-shadow: 1px 1px 2px #888; */
}

.layout .user-selection-box .user-info .action button:hover {
  background-color: #b7b7b7;
}

.layout .user-selection-box .user-info .info {
  width: 100%;
  overflow: hidden;
}

.layout .user-selection-box .user-info .info .info-container {
  display: flex;
  height: 46px;
  background-color: #f5f5f5;
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem;
  /* box-shadow: 1px 1px 2px #888; */
  /* padding-left: 4px; */
  margin-right: 2px;
}

.layout .user-selection-box .user-info .info .info-container .display-avatar {
  height: 38px;
  width: 38px;
  min-width: 38px;
  min-height: 38px;
  background-color: #b6d3f2;
  border-radius: .25rem;
  margin: 4px;
  padding: 4px;
  overflow: hidden;
  color: #666;
  /* box-shadow: -1px -1px 2px #888; */
}

.layout .user-selection-box .user-info .info .info-container .display-avatar * {
  height: 100%;
  width: 100%;
}

.layout .user-selection-box .user-info .info .info-container.group {
  cursor: pointer;
  /* margin-bottom: 4px; */
}

.layout .user-selection-box .user-info:hover>.info>.info-container,
.layout .user-selection-box .user-info .info:hover .info-container.group,
.layout .user-selection-box .user-info .info .info-container:hover {
  background-color: #e2e2e8;
}

.layout .user-selection-box .user-info .info .user-info-detail {
  width: 100%;
  padding: 0 6px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.layout .user-selection-box .user-info .info .user-info-detail .top-info,
.layout .user-selection-box .user-info .info .user-info-detail .bottom-info {
  height: 22px;
}

.layout .btn-add-user {
  border: 0;
}

.layout .btn-add-user .material-icons {
  width: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.layout .doc-info .input-group {
  margin-top: 10px;
  min-width: 640px;
  height: 46px;
}

.layout .doc-info .document-info-key {
  min-width: 240px;
  font-size: .81rem;
  color: #535963;
}

@media only screen and (max-width:742px) {
  .layout .doc-info {
    margin-bottom: 50px;
  }

  .layout .doc-info .document-info-key {
    font-size: 9px;
    height: 28px;
  }

  .layout .doc-info .signer-list-wrapper .document-info-key {
    height: 14px;
  }

  .responsiveCSS .form-row {
    margin: 0;
  }
}

.layout .doc-info .document-info-value {
  height: 100%;
  font-size: .85rem;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  justify-content: center;
  /* overflow: hidden;
    white-space: nowrap; */
}

@media only screen and (max-width:742px) {
  .layout .doc-info .document-info-value {
    font-size: .65rem;
    height: 28px;
  }
}

.layout .filebox-shortcut-path {
  background-color: #fff;
}

.layout .document-row-list.has-linkage {
    background-color: #dff2fc;
}

.layout .document-row-list.selected {
  background-color: #e1edff;
}

.layout .document-row-list.selected:hover {
  background-color: #cfe1f9;
}

.layout .document-row-list:hover {
  background-color: #f2f2f8;
}

.layout .document-row-list td {
  vertical-align: middle;
  padding: 0;
}

.layout .document-row-list .record-not-found {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 20px;
  font-size: 18px;
}

.table {
  color: inherit;
  table-layout: fixed;
}

.th-stamp-box,
.th-stamp-delete {
  width: 2.35%;
  max-width: 40px;
}

.th-creator {
  max-width: 90px;
  min-width: 8em;
  width: 7%;
}

.th-function-type {
  width: 7.04%;
  max-width: 90px;
}

.th-doctype {
  min-width: 8em;
}

.th-preservation-require {
  width: 8.21%;
  max-width: 105px;
}

.th-imprints {
  width: 26.18%;
  max-width: 335px;
}

.th-action {
  width: 120px;
}

.th-counter-party {
  width: 20.44%;
  max-width: 300px;
}

.th-amount-info {
  width: 12.38%;
  max-width: 120px;
}

.th-document-created-date {
  width: 7.82%;
  max-width: 100px;
}


.layout .document-row-list .counter-party-info span {
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.layout .document-row-list .title-info .document-files {
  display: flex;
}

.layout .document-row-list .title-info .document-files .file-name-label {
  overflow: hidden;
  text-overflow: ellipsis;
}

.layout .document-row-list .title-info .more-files {
  position: relative;
}

.layout .document-row-list .title-info .more-files .more-files-link {
  margin: 0 8px;
  cursor: default;
}

.layout .document-row-list .title-info .more-files .more-files-block {
  display: none;
}

.layout .document-row-list .title-info .more-files .more-files-block .file-label:hover {
  background-color: #e2e2e8;
}

.layout .document-row-list .title-info .more-files:hover .more-files-block {
  display: block;
  position: absolute;
  top: 0;
  left: 100%;
  width: auto;
  min-width: 200px;
  /* max-width: 400px; */
  z-index: 10;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.layout .document-row-list .title-info .more-files .more-files-block .file-label {
  list-style: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 4px 15px;
}

.layout .document-row-list .amount-info .inner-container span {
  text-align: right;
}

.layout .document-row-list .doc-row-action,
.layout .document-row-list .sign-status {
  vertical-align: middle;
  text-align: center;
  color: #9495a7;
}

.layout .document-row-list .document-download {
  font-size: 24px;
}

.layout .document-row-list .document-downloading {
  font-size: 24px;
  cursor: default;
}

.layout .document-row-list .document-delete {
  font-size: 24px;
  color: #ec163f;
}

.layout .document-row-list .action-icon {
  display: inline-block;
  padding: 0 4px;
  font-size: 24px;
}

.layout .document-row-list .action-button {
  cursor: pointer;
}

@media only screen and (max-width:742px) {
  .layout .document-detail .signer-list-wrapper .user-info {
    height: 36px;
  }

  .layout .document-detail .signer-list-wrapper .user-info .display-avatar {
    min-width: 28px;
    min-height: 28px;
    width: 28px;
    height: 28px;
  }

  .layout .document-detail .signer-list-wrapper .user-info .top-info {
    font-size: 12px;
  }

  .layout .document-detail .signer-list-wrapper .user-info .bottom-info {
    font-size: 11px;
  }
}

.layout .document-detail .signer-list-wrapper .sign-status.signed,
.layout .document-detail .signer-list-wrapper .user-info .sign-status.signed .icon-container {
  padding: 0;
  text-align: left;
}

.layout .document-detail .signer-list-wrapper .user-info .icon-container {
  width: 24px;
  margin: 0 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(96, 95, 110);
}

.layout .document-detail .signer-list-wrapper .user-info .icon-container>svg {
  font-size: 23px;
}

@media only screen and (max-width:742px) {
  .layout .document-detail .signer-list-wrapper .user-info .icon-container>svg {
    font-size: 21px;
  }
}

.layout .document-detail .signer-list-wrapper .user-info .icon-container span.action-resend-email {
  width: 22px !important;
  height: 22px !important;
  cursor: pointer;
}

.layout .document-detail .signer-list-wrapper .sign-status.token-expired,
.layout .document-detail .signer-list-wrapper .user-info .sign-status.token-expired .icon-container,
.layout .document-row-list .sign-status.wait-sign {
  color: #ec163f;
}

.layout td>div,
.layout th>div {
  padding: 5px 5px;
  min-height: 60px;
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.layout td:first-child>div,
.layout th:first-child>div {
  margin-left: 10px;
}

.layout td:last-child>div,
.layout th:last-child>div {
  margin-right: 10px;
}

.layout tbody tr {
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #e2e2e8;
  padding: 0;
  min-height: 60px;
  font-size: clamp(0.65rem, 1.05vw, 0.875rem);
}

.layout .document-row-list td .inner-container span {
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
}

.layout .document-row-list .sign-status .inner-container {
  align-items: flex-start;
  justify-content: center;
}

.layout .document-row-list .evaluation-summary-info {
  font-size: 14px;
  line-height: 18px;
  padding: 0 8px;
}


/* .layout .document-row-list .document-evaluated-status { */


/* display: flex;
    align-items: flex-end; */


/* height: 27px; */


/* } */

.layout .document-row-list .evaluated-status .status-label {
  border: 2px solid #067bfc;
  color: #067bfc;
  border-radius: 12px;
  height: 24px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding: 2px 10px;
  font-size: 12px;
  font-weight: bold;
}

.layout .evaluate-option {
  margin-left: 10px;
}

.layout .evaluate-option label {
  margin-left: 0;
  margin-bottom: 0;
}

.layout .date-noti label {
  font-size: 14px;
  color: #535963;
}

.css-6rspzu-MuiFormControlLabel-root .MuiFormControlLabel-label {
  font-size: 14px;
}

@media only screen and (max-width:742px) {

  .css-qfz70r-MuiFormGroup-root {
    margin-left: 15px;
  }

  .css-6rspzu-MuiFormControlLabel-root .MuiFormControlLabel-label {
    font-size: 12px;
  }

  .css-7njd72-MuiButtonBase-root-MuiRadio-root {
    padding: 3px 9px;
  }
}

.layout .evaluate-header.bg-lightblue,
.layout .evaluate-info.bg-lightblue {
  background-color: #dff2fc;
}

.layout .document-row-list .evaluated-status .icon {
  margin-right: 4px;
  height: 24px;
  font-size: 24px;
  color: #067bfc;
}

.layout .document-row-list .evaluated-status-icon-medium {
  height: 16px;
  width: 16px;
  color: #8d8d8d;
}

.layout .document-row-list .evaluated-status-icon-medium.ok,
.layout .document-row-list .evaluated-status .icon.ok {
  color: #027d02;
}

.layout .document-row-list .evaluated-status-icon-medium.ng,
.layout .document-row-list .evaluated-status .icon.ng {
  color: #fe8f00;
}

.layout .document-row-list .evaluated-status-mark-medium {
  height: 16px;
  width: 16px;
}

.layout .document-row-list .evaluated-status-mark-mini {
  height: 10px;
  width: 10px;
}

.layout .document-row-list .evaluated-status-mark-medium,
.layout .document-row-list .evaluated-status-mark-mini {
  border-radius: 50%;
  background-color: #8d8d8d;
}

.layout .document-row-list .evaluated-status-mark-medium.ok,
.layout .document-row-list .evaluated-status-mark-mini.ok {
  background-color: #027d02;
}

.layout .document-row-list .evaluated-status-mark-medium.ng,
.layout .document-row-list .evaluated-status-mark-mini.ng {
  background-color: #fe8f00;
}

.layout .verification-container,
.layout .sender-container,
.layout .signer-container {
  display: flex;
  flex-direction: column;
}

.layout .table thead {
    position: relative;
    z-index: 1;
}

.layout .evaluation-container .header-wrapper,
.layout .circle-container .header-wrapper,
.layout .verification-container .header-wrapper,
.layout .sender-container .header-wrapper,
.layout .signer-container .header-wrapper,
.layout .table thead tr,
.layout #datalist thead tr,
.stickyHeader,
.search-container .header-wrapper {
  position: sticky;
  z-index: 2;
  border-bottom: 1px solid #e2e2e8;
}

.layout .evaluation-container .header-wrapper.above,
.layout .circle-container .header-wrapper.above,
.layout .verification-container .header-wrapper.above,
.layout .sender-container .header-wrapper.above,
.layout .signer-container .header-wrapper.above {
  border-bottom: none;
}

.layout th>div {
  min-height: 30px;
}

.stickyHeader {
  background-color: #fff;
  top: 0;
  height: 60px;
}

.layout .table thead tr,
.layout #datalist thead tr {
  top: 60px;
  background: #fff;
  border: none;
  border-radius: 0;
  padding: 0;
}

/* .layout thead tr:after {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: " ";
    position: absolute;
    border-bottom: 1px solid #e2e2e8;
} */

.layout .header-wrapper.above {
  padding: 0;
  height: auto;
}

.layout .body-wrapper {
  width: 100%;
  /* height: calc(100% - 60px); */
}

.layout .document-detail {
  position: absolute;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 4;
  transition-duration: 0.4s;
  transition-timing-function: ease;
}

.layout .document-detail:not(.active) {
  margin-left: 100%;
  transform: rotateY(90deg);
  transform-style: preserve-3d;
}

.layout div.document-detail.active+div {
  overflow: hidden;
  height: 100%;
}

.layout .document-detail.static {
  position: relative;
  width: 100%;
  height: 100%;
  margin-left: 0;
  background-color: #fff;
  transform: none;
}

.layout .document-detail .document-detail-wrapper {
  display: flex;
  flex-direction: column;
  /* min-height: 100%; */
  /* height: calc(100% - 60px); */
  height: 100%;
}

.layout .document-detail .document-detail-wrapper .body-wrapper .document-detail-form {
  overflow: hidden;
}

.layout .template-new .btn-action,
.layout .template-new .btn-return,
.layout .search-container .btn-action,
.layout .search-container .btn-return {
  color: #626669;
  border: none;
  background-color: transparent;
  padding: 0 8px;
  font-size: 30px;
  width: 46px;
}

.layout .document-detail-container .form-group {
  min-height: 40px;
  margin-bottom: 22px;
}

@media only screen and (max-width:742px) {
  .layout .document-detail-container .form-group {
    height: inherit;
  }
}

.layout .document-detail-container .form-group .document-info-value {
  /* border:none; */
  color: #062b60;
}

.layout .document-detail-container .info-label.form-group {
  max-height: 40px;
  padding: 10px 0;
  margin: 0;
  height: unset;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.layout .document-detail-container .document-status-label {
  display: flex;
  align-items: center;
  justify-content: center;
}

.layout .document-detail-container .document-status-label .document-info-value {
  border: 3px solid #3b74d7;
  color: #3b74d7;
  border-radius: 18px;
  height: 36px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding: 4px;
  font-weight: 500;
}

@media only screen and (max-width:742px) {
  .layout .document-detail-container .document-status-label .document-info-value {
    height: 30px;
  }
}

.layout .status-container {
  display: flex;
  align-items: flex-end;
  height: 27px;
}

.layout .document-progress {
  display: flex;
  align-items: flex-end;
  height: 27px;
  margin-right: 4px;
}

.layout .evaluate-detail {
  display: flex;
  align-items: center;
  height: 27px;
}

.layout .evaluate-detail.evaluated-status .icon {
  font-size: 16px;
}

.layout .document-progress .status-label {
  border: 2px solid #1976d2;
  color: #1976d2;
  border-radius: 11px;
  height: 22px;
  line-height: 22px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 10px;
  font-size: .7rem;
  font-weight: 500;
}

.layout .unsigned-container {
  display: flex;
  align-items: flex-end;
  height: 27px;
}

.layout .document-progress.draft .status-label,
.layout .document-detail-container .document-status-label .document-info-value.draft {
  border: 2px solid #a8aabf;
  color: #a8aabf;
}

.layout .document-progress.expired .status-label,
.layout .document-detail-container .document-status-label .document-info-value.expired {
  color: #6f718c;
  border-color: #6f718c;
}

.layout .document-progress.file-error .status-label,
.layout .document-progress.error .status-label,
.layout .document-detail-container .document-status-label .document-info-value.file-error,
.layout .document-detail-container .document-status-label .document-info-value.error {
  color: #e0463b;
  border-color: #e0463b;
}

.label-error {
  color: #e0463b;
  border-color: #e0463b;
}

.layout .document-progress.guest-expired .status-label,
.layout .document-detail-container .document-status-label .document-info-value.guest-expired {
  color: #c4830c;
  border-color: #c4830c;
}

.layout .document-progress.signed .status-label,
.layout .document-progress.send-sender .status-label,
.layout .document-progress.confirmed .status-label,
.layout .document-detail-container .document-status-label .document-info-value.signed,
.layout .document-detail-container .document-status-label .document-info-value.send-sender,
.layout .document-detail-container .document-status-label .document-info-value.confirmed {
  color: #078c07;
  border-color: #078c07;
}

.layout .document-detail-container .document-status-label .document-info-value .icon {
  font-size: 24px;
}

@media only screen and (max-width:742px) {
  .layout .document-detail-container .document-status-label .document-info-value .icon {
    font-size: 18px;
  }
}

.layout .document-detail-container .document-status-label .document-info-value .label {
  width: 100%;
  text-align: center;
}

.layout .document-detail .signer-list-wrapper {
  display: block;
}


/* .layout .btn.new-document {
    margin: 0;
} */

.layout .warning {
  color: #e9ae0a;
}

.layout .no-certificate-warning {
  font-size: 24px;
  color: #e9ae0a;
}

.layout .guest-no-certificate-warning {
  font-size: 20px;
  color: #e9ae0a;
  position: absolute;
  left: 8px;
}

.layout .guest-no-certificate-warning-title {
  font-size: 18px;
  color: #e9ae0a;
  margin-right: 8px;
}

.layout .guest-certificate-selection {
  width: 100%;
  min-width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-right: 15px;
}

.layout .guest-certificate-selection .dropdown-toggle.nav-link {
  padding: 0;
}

.layout .guest-certificate-selection .dropdown-menu .dropdown-item {
  padding-left: 40px;
  position: relative;
}

.layout .document-selected-list {
  margin: 0;
}

.layout .document-selected-list .document-selected-item {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.layout .document-selected-header {
  font-weight: 500;
  padding: 15px 15px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.menu-hidden.active .document-selected-list {
  padding: 15px 8px 15px 15px;
  overflow: auto;
  height: calc(100% - 54px);
}

.layout .document-selected-list .document-info {
  font-size: 0.81rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 4px 10px 4px 0;
}

.layout .document-selected-list .document-action {
  color: #535963;
  padding: 0px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.layout .document-selected-list .document-action .btn-remove {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  padding: 8px;
}

.layout .document-selected-list .document-action:hover .btn-remove {
  background-color: #f2f2f8;
}

.layout .page-error {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.layout .template-preview,
.layout .template-new {
  background-color: #fff;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 100%;
  transition-duration: 0.4s;
  transition-timing-function: ease;
}

.layout .template-row-contrainer {
  position: relative;
  height: 100%;
}

.layout .template-preview.active {
  left: 0;
}

.layout .template-new.active {
  left: 0;
}

.access-log-head {
  margin: 4px;
  padding: 1px;
  line-height: 16px;
  display: flex;
}

.access-log-body {
  font-size: 0.75rem;
  overflow: auto;
  padding-left: 10px;
}

.access-log-collapse {
  height: 0px;
}

.access-log-selector-label {
  margin-bottom: 4px;
}

.access-log-selector-itemlist {
  padding: 20px;
  height: 400px;
  border-radius: 4px;
  border: 1px solid #ced4da;
  overflow-y: scroll;
}

.access-log-selector-item label {
  display: block;
  cursor: pointer;
  /* box-shadow: 0 0 6px 2px #eee; */
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.access-log-selector-item div {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  border: 1px solid #ced4da;
}

.layout .accesslog {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #f2f2f8;
  border-left: 1px solid #e2e2e8;
  padding: 15px 15px 15px 15px;
  left: 100%;
  transition-duration: 0.4s;
  transition-timing-function: ease;
  z-index: 10;
  font-size: .81rem;
  word-wrap: break-word;
}

.layout .accesslog.active {
  left: 0%;
}

.operation-log-top {
  border-bottom: 1px solid #dadae4;
}

.operation-log-top .lz-flex-1 {
  padding: 15px 0;
}

.operation-log-top .operation-log-update-date,
.operation-log-top .operation-log-update-user {
  font-size: .75rem;
  color: #545d75;
}

.operation-log-top .operation-log-content {
  margin-top: 10px;
  font-size: 0.80rem;
  font-weight: 500;
}

.pg-viewer-wrapper #docx {
  background-color: #ddd;
  height: auto !important;
  padding: 20px 0;
}

.layout .doc-key-container {
  list-style: none;
  padding: 0;
  margin: 0;
}

.layout .doc-key-item {
  padding: 4px 8px;
  margin: 8px 4px;
  background-color: #f5f5f5;
  border-left: 10px solid #ddd;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
}

.layout .form-group.no-margin {
  margin: 0;
}

.layout .form-group.no-margin.doc-key-item-list {
  height: 100%;
  overflow-y: auto;
}

.layout .ReactTable {
  position: absolute;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow: auto;
  top: 0;
  left: 0;
  border: none;
}

.layout .table td,
.table thead th {
  border-top: solid 0px !important;
  border-bottom: solid 0px !important;
}

.table thead th {
  font-size: 0.75rem;
  font-size: clamp(0.65rem, 1.03vw, 0.75rem);
  color: #828688;
  font-weight: 500;
  padding: 0;
  white-space: nowrap;
}

.layout .table-bordered thead th {
  border-top: solid 0px !important;
  vertical-align: middle;
  white-space: nowrap;
  text-align: center;
}

.layout .table-bordered tbody td {
  vertical-align: middle;
  white-space: nowrap;
}

.layout .record-form-detail {
  position: fixed;
  height: calc(100% - 60px);
  top: 60px;
  left: 110%;
  background-color: #fff;
  height: calc(100% - 60px);
  width: 40%;
  transition-duration: 0.4s;
  transition-timing-function: ease;
  overflow: auto;
  border-top: 1px solid #ddd;
  border-left: 1px solid #ddd;
  padding-top: 20px;
  margin-top: -1px;
}

.layout .record-form-detail.active {
  left: 60%;
}

.layout .record-form-detail-head,
.layout .hader-form-action {
  display: flex;
}

.layout .record-form-detail-body {
  overflow: auto;
}

.layout .record-form-detail-body-container {
  display: flex;
  flex-direction: column;
}

.layout .record-form-detail-body .form-group {
  margin-bottom: 0px;
}

.layout .record-form-detail-body .form-group .input-group-prepend .btn {
  height: unset;
}

.layout .btn-attribute {
  position: absolute;
  z-index: 2;
  top: 0;
  background-color: transparent;
  border: none;
  padding: 0;
  height: 40px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.layout .add-attribute {
  left: -56px;
}

.layout .remove-attribute {
  left: -30px;
}

.layout .forward-user-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.layout .forward-user-list .user-info {
  background-color: #f5f5f5;
  border-radius: 50px 8px 8px 50px;
  cursor: pointer;
}

.layout .forward-user-list .user-info:hover,
.layout .forward-user-list .user-info.selected {
  background-color: #cccccc;
}

.layout .forward-user-list .user-avatar {
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  border-radius: 40px;
  display: block;
  padding: 4px;
}

.layout .forward-user-wrapper .forward-user-info {
  height: 100%;
  width: 100%;
  padding: 0 15px;
}

.layout .forward-user-wrapper .forward-user-card {
  height: unset;
  /* padding: 15px; */
}

.layout .forward-user-wrapper .forwardUser-profile {
  display: flex;
  align-items: center;
  background-color: #f2f2f8;
  word-break: break-all;
  border-radius: 999px;
}

.layout .forward-user-wrapper .forward-user-card .user-avatar {
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  border-radius: 40px;
  display: block;
  padding: 4px;
}

.layout .viewer-avatar {
  border-radius: 50px;
  overflow: hidden;
  height: 20px;
  width: 20px;
  margin-left: -7px;
  margin-right: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.layout .forward-user-wrapper .forward-user-card .user-profile {
  width: calc(100% - 48px);
  padding: 0 4px;
}

.layout .forward-user-wrapper .forward-user-card .top-info {
  line-height: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.layout .forward-user-wrapper .forward-user-card .top-info span {
  font-size: 0.83rem;
  font-weight: 500;
}

.layout .forward-user-wrapper .forward-user-card .bottom-info {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.layout .forward-user-wrapper .forward-user-card .bottom-info span {
  font-size: 0.81rem;
}

.layout .display-avatar div:not([avatar]),
.layout .display-avatar svg,
.layout .viewer-avatar div:not([avatar]),
.layout .viewer-avatar svg,
.layout .user-avatar div,
.layout .user-avatar svg {
  display: block;
  width: 100% !important;
  height: 100% !important;
  padding: 1px;
}

.app-cert-dialog {
  min-width: 800px;
}

.icon.tenant-switch .MuiBox-root .MuiInput-root::before,
.header-right .MuiBox-root .MuiInput-root::before {
  display: none;
}

.icon.tenant-switch .MuiBox-root .MuiInput-root>div:focus,
.header-right .MuiBox-root .MuiInput-root>div:focus {
  background: none;
}

.icon.tenant-switch .MuiBox-root .MuiInput-root>div,
.header-right .MuiBox-root .MuiInput-root>div,
ul.MuiList-root li {
  font-size: .875rem;
}

.layout.modal .modal-dialog .full-height-modal-body {
  height: calc(100vh - 15rem);
}

.layout .flex-center {
  align-items: center;
}

.layout .user-linkage-icon {
    width: 40px;
    height: 40px;
}

@media (min-width: 576px) {

  .layout.modal.modal-responsive .modal-dialog.modal-xl,
  .layout.modal.modal-responsive .modal-dialog.modal-lg {
    max-width: 50%;
    margin: 1.75rem auto;
  }

  .layout.modal.modal-responsive .modal-dialog.modal-sm {
    max-width: 30%;
    margin: 1.75rem auto;
  }
}

@media (min-width: 992px) {
  .layout.modal.modal-responsive .modal-dialog {
    max-width: "remove";
  }
}


#page-settings-guest .document-row-list td {
  max-width: 0;
  font-size: clamp(10px, 1vw, 13px);
}

#page-settings-guest .document-row-list td.no-overflow {
  max-width: 1px;
  overflow: visible;
  min-width: 16em;
}

#page-settings-guest .document-row-list td>div {
  width: 100%;
  font-size: clamp(10px, 1vw, 13px);
}

#page-settings-guest .document-row-list td.no-overflow>div {
  align-items: center;
}


/* table.user-settings-list {
  table-layout: fixed;
} */
.user-settings-list th {
  white-space: nowrap;
}

.user-settings-list .user-user-id {
  width: 6.9%;
}

.user-settings-list .user-user-name {
  width: 8.3%;
}

.user-settings-list .user-user-email {
  width: 20%;
}

.user-settings-list .user-personal-jcan {
  width: 20%;
}

.user-settings-list .user-custom-certificate {
  width: 20%;
}

.user-settings-list .user-administrator-role>div {
  align-items: center;
}

.user-settings-list .user-user-status {
  width: auto;
}

.user-settings-list .user-user-status div {
  align-items: center;
}

.user-settings-list .user-user-trash {
  width: 3.32%;
}

.user-settings-list .css-dkl93a-MuiChip-root {
  font-size: clamp(10px, 1vw, 13px);
}

/* table.guest-settings-list {
  table-layout: fixed;
} */

.guest-settings-list .guest-user-id {
  width: 80px;
}

/* .guest-settings-list .guest-user-name {
  width: 10%;
} */

/* .guest-settings-list .guest-company-name {
  width: 20%;
} */

.guest-settings-list .guest-user-email {
  width: 20%;
}

/* .guest-settings-list .guest-personal-jcan {
  width: 20%;
} */

/* .guest-settings-list .guest-custom-certificate {
  width: 20%;
} */



/* verification-stamp */
.verification-stamp-detail {
  width: 60px;
}

.verification-stamp-creator {
  width: 8em;
}

.verification-stamp-function {
  width: 6em;
}

.verification-stamp-imprints {
  width: 11em;
}

.verification-stamp-doctitle {
  min-width: 150px;
}

.verification-stamp-amount {
  width: 10%;
}

.verification-stamp-date {
  width: 8em;
}