.auth.contrainer-wrapper {
    display: flex;
    justify-content: center;
    min-height: 100vh;
    background-color: #f2f2f8;
    font-family: 'Mplus 1p', sans-serif;
}
.auth.contrainer-wrapper.horizontal {
    display: flex;
    justify-content: center;
    width: 100vh;
}

.auth .contrainer-block {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 0px 0 0 0;
    width: 100%;
}

.auth .logo {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
}

.auth .logo img {
    vertical-align: middle;
    border-style: none;
    max-width: 500px;
    width: 100%;
    height: 100px;
}

.auth .logo-horizontal {
    display: flex;
    margin-bottom: 24px;
    min-height: 30px;
}

.auth .logo-horizontal img {
    vertical-align: middle;
    border-style: none;
}

.auth .form-wrapper {
    background: #FFFFFF;
    box-shadow: 0px 2px 6px #cecfd9;
    border-radius: 6px;
    padding: 28px;
    position: relative;
    display: flex;
    flex-direction: column;
}

.auth .form-wrapper.login {
    width: 100%;
    max-width: 500px;
}

.auth .form-wrapper.registration {
    max-width: 1200px;
    margin: 0 auto;
}

.auth .form-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    min-height: 40px;
    flex-wrap: wrap
}

.auth .header-info {
    color: #4C4F5E;
    font-size: 1.5rem;
    font-weight: 500;
}

.auth .registration-link a {
    color: #1069D5;
    font-size: 16px;
    font-weight: 500;
    text-decoration: none;
    cursor: pointer;
}

.auth .form-container {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.auth .fields-container {
    position: relative;
}

.auth #form_password_recovery {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.auth .form-bottom {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
}

.auth .form-bottom .btn-link,
.auth .form-bottom a:not(.btn) {
    /* color: #1069D5; */
    font-weight: 500;
}
.auth .form-bottom a:not(.btn) {
    display: block;
    font-size: .85rem;
    padding-top: 10px;
}


.auth .wrapper-button-submit {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    cursor: pointer;
}

.auth .wrapper-button-cancel a,
.auth .wrapper-button-submit a {
    width: 160px;
    height: 46px;
    font-size: 16px;
    /* margin: 6px 0; */
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.auth .wrapper-button-cancel button,
.auth .wrapper-button-submit button {
    position: relative;
    width: 160px;
    height: 46px;
    font-size: 16px;
    /* margin: 6px 0; */
}

.auth .custom-form-outline {
    height: 74px;
}

.auth .was-validated .form-control:valid,
.auth .was-validated .form-control:invalid {
    margin-bottom: 0;
}

.auth .footer {
    margin-top: 17px;
    margin-bottom: 16px;
    position: fixed;
    bottom: 0;
    right: 49px;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    color: #9398ab;
}
.auth .faqButton{
    text-align: right;
}
.auth .material-icons {
    position: absolute;
    top: 10px;
}

.auth .material-icons:first-child {
    left: 0;
}

.auth .material-icons:last-child {
    right: 0;
}

.auth .data-list dd {
    margin-left: 26px;
}

.auth .term-condition-detail {
    font-size: 0.90rem;
    overflow: auto;
    height: 55vh;
    margin-bottom: 15px;
    background-color: #f2f2f8;
    padding: 10px;
}

.auth .data-list .form-group {
    height: 50px;
}

.auth .data-list .form-label {
    font-weight: bold;
    margin-bottom: 0;
}

.auth .data-list .form-control {
    border: 0;
    padding: 0 4px;
    margin-left: 20px;
}

.auth .signing-in-loading {
    height: 46px;
    min-width: 160px;
    padding: 4px;
    color: #666;
}
