.file-explorer {
    height: 100%;
    width: 100%;
}

.file-explorer .file-explorer-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.file-explorer .toolbar {
    padding: 6px 10px;
    height: 60px;
    width: 100%;
    display: flex;
    border-bottom: 1px solid #e2e2e8;
}

.file-explorer .toolbar-items-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
}

.file-explorer .toolbar-items-container .action-icon {
    width: 40px;
    height: 40px;
    color: #666;
    padding: 4px;
    margin: 0 6px;
    cursor: pointer;
}

.file-explorer .toolbar-items-container .action-icon.disabled {
    cursor: not-allowed;
    color: #c3c3c3;
}

.file-explorer .toolbar-left,
.file-explorer .toolbar-center{
    height: 100%;
    display: flex;
    align-items: center;
}


.file-explorer .container {
    height: 100%;
    margin: 0;
    padding: 0;
    width: 100%;
    max-width: 100%;
}

.file-explorer .container .drawer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
}

.file-explorer .container .drawer .tree-panel {
    height: 100%;
    max-height: 100%;
    width: 30%;
    min-width: 200px;
    max-width: 340px;
    user-select: none;
    position: relative;
    display: flex;
    overflow: hidden;
    flex-direction: column;
}

.file-explorer .container .drawer .tree-panel .tree-wrapper {
    padding-top: 10px;
    position: absolute;
    height: 100%;
    width: 100%;
    overflow: auto;
}

.file-explorer .container .drawer .tree-panel .tree-wrapper>ul {
    padding-left: 10px;
}

.file-explorer .container .drawer .tree-panel ul {
    padding: 0;
    width: 100%;
    padding-left: 10px;
    display: flex;
    flex-direction: column;
}

.file-explorer .container .drawer .tree-panel li {
    min-height: 32px;
    list-style: none;
    display: flex;
    flex-direction: column;
    font-size: .871rem;
}

.file-explorer .container .drawer .tree-panel li.collapse .path-children {
    display: none;
}

.file-explorer .container .drawer .tree-panel .active>.path-label .directory-icon,
.file-explorer .container .drawer .tree-panel .active>.path-label .directory-label,
.file-explorer .container .drawer .tree-panel .has-active-child.collapse>.path-label .directory-icon,
.file-explorer .container .drawer .tree-panel .has-active-child.collapse>.path-label .directory-label {
    color: #1069d5;
    font-weight: bold;
}

.file-explorer .container .drawer .tree-panel .path-label:hover .label-group {
    font-weight: 500;
    color: #1069d5;
}

.file-explorer .container .drawer .tree-panel .path-label {
    height: 32px;
    position: relative;
    display: flex;
}
.file-explorer .container .drawer .tree-panel .path-label:hover{
    background: #f2f2f8;
}

.file-explorer .container .drawer .tree-panel .caret-expand {
    width: 18px;
    color: #062b60;
}

.file-explorer .container .drawer .tree-panel .caret-expand .icon {
    width: 16px;
}

.file-explorer .container .drawer .tree-panel .caret-expand .loaded-no-children {
    visibility: hidden;
}

.file-explorer .container .drawer .tree-panel .directory-icon {
    width: 20px;
    min-width: 20px;
    color: #062b60;
}

.file-explorer .container .drawer .tree-panel .directory-icon .icon {
    font-size: 18px;
}

.file-explorer .container .drawer .tree-panel .directory-label {
    height: 100%;
    display: flex;
    align-items: center;
    white-space: nowrap;
    padding: 0 4px;
}


/* .file-explorer .container .drawer .tree-panel .directory-label span {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
} */

.file-explorer .container .drawer .tree-panel .icon-children-loading {
    position: absolute;
    height: 100%;
    width: 32px;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.file-explorer .container .drawer .tree-panel .icon-children-loading i {
    width: 32px;
    height: 32px;
}

.file-explorer .container .drawer .tree-panel .action {
    cursor: pointer;
    height: 100%;
    display: flex;
    align-items: center;
    color: #062b60;
    text-decoration: none;
}

.file-explorer .container .drawer .tree-panel .action.label-group {
    width: 100%;
    padding: 0 4px;
}

.file-explorer .container .drawer .panel-separator {
    width: 4px;
    cursor: col-resize;
    display: flex;
    justify-content: center;
}

.file-explorer .container .drawer .panel-separator .line {
    width: 4px;
    height: 100%;
    border-left: 1px solid #e2e2e8;
    border-right: 1px solid #e2e2e8;
}

.file-explorer .container .drawer .content-panel {
    height: 100%;
    width: 100%;
}

.file-explorer .container .drawer .content-panel-wrapper {
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
}

.file-explorer .container .drawer .content-body-inner {
    position: absolute;
    overflow: auto;
    height: 100%;
    width: 100%;
    padding: 10px;
}

.file-explorer .container .drawer .content-action-bar {
    height: 50px;
    border-bottom: 1px solid #e2e2e8;
}

.file-explorer .container .drawer .content-body {
    height: 100%;
    width: 100%;
    position: relative;
}

.file-explorer .container .drawer .content-body.empty-folder {
    display: flex;
    align-items: center;
    justify-content: center;
}
.file-explorer .container .drawer .content-body.empty-folder>img{
    width: 256px;
}

.file-explorer .container .drawer .content-body.view-icon .item-wrapper {
    display: block;
    position: relative;
    width: 95px;
    height: auto;
    float: left;
    margin: 3px;
}

.file-explorer .container .drawer .content-body.view-icon .item-wrapper .item {
    /* height: 100%; */
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid transparent;
}

.file-explorer .container .drawer .content-body.view-icon .item-wrapper .icon.pdf {
    color: #ed7171;
}

.file-explorer .container .drawer .content-body.view-icon .item-wrapper .icon.xls,
.file-explorer .container .drawer .content-body.view-icon .item-wrapper .icon.xlsx {
    color: #50884d;
}

.file-explorer .container .drawer .content-body.view-icon .item-wrapper:hover {
    z-index: 2;
}

.file-explorer .container .drawer .content-body.view-icon .item-wrapper .item:hover {
    background-color: #f2f2f8;
}

.file-explorer .container .drawer .content-body.view-icon .item-wrapper .item.active {
    background-color: #cce8ff;
    border: 1px solid #99d1ff;
}

.file-explorer .container .drawer .content-body.view-icon .item-wrapper .item .icon-wrapper {
    width: 70px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #545d75;
    position: relative;
}

.file-explorer .container .drawer .content-body.view-icon .item-wrapper .item .icon-wrapper .icon {
    font-size: 35px;
}

.file-explorer .container .drawer .content-body.view-icon .item-wrapper .item .icon-wrapper .icon-linkage {
    position: absolute;
    color: #062b60;
    bottom: 13px;
    right: 25px;
    font-size: 20px;
}

.file-explorer .container .drawer .content-body.view-icon .item-wrapper .item .item-label {
    height: 24px;
    min-height: 24px;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: center;
    font-size:0.78rem;
}

.file-explorer .container .drawer .content-body.view-icon .item-wrapper .item .item-label span {
    white-space: nowrap;
    width: 100%;
}


.file-explorer .footer {
    width: 100%;
}

.file-explorer .footer .footer-inner {
    height: 30px;
    padding: 2px 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #e2e2e8;
    white-space: nowrap;
    font-size:0.81rem;
}

.file-explorer .footer .footer-inner .footer-center {
    width: 100%;
}

.file-explorer .secondary-action-bar-container {
    height: 50px;
    display: flex;
    align-items: center;
}

.file-explorer .secondary-action-bar-container .address-bar {
    height: 40px;
    width: 100%;
    padding: 0 15px;
    margin: 0 15px;
    display: flex;
    align-items: center;
    background: #f2f2f8;
    border-radius: 20px;
}

.file-explorer .secondary-action-bar-container .action-back {
    margin-left: 10px;;
}
.file-explorer .toolbar-right svg,
.file-explorer .secondary-action-bar-container .action-back svg,
.file-explorer .secondary-action-bar-container .action-upload svg,
.file-explorer .secondary-action-bar-container .action-reload svg  {
    font-size: 28px;
}
.file-explorer .secondary-action-bar-container .action-reload{
    margin-right:10px;
}
.file-explorer .secondary-action-bar-container .action-reload svg {
    transform: rotate(-45deg);
}

.file-explorer .secondary-action-bar-container .filebox-upload {
    margin: 0;
    display: flex;
}

.file-explorer .secondary-action-bar-container .action-upload {
    width: 40px;
    display: flex;
    justify-content: center;
}


.file-explorer .secondary-action-bar-container .action-upload .disabled,
.file-explorer .secondary-action-bar-container .action-reload .disabled {
    cursor: not-allowed;
    user-select: none;
    /* color: #c3c3c3; */
}

.file-explorer .secondary-action-bar-container .address-bar .address-nav {
    display: flex;
    align-items: center;
}

.file-explorer .secondary-action-bar-container .address-bar .address-nav span {
    height: 30px;
    padding: 0 4px;
    margin: 0 4px;
    cursor: pointer;
    border: 1px solid transparent;
    font-size: 0.871rem;
    line-height: 26px;
}

.file-explorer .secondary-action-bar-container .address-bar .address-nav span:hover {
    background-color: #cce8ff;
    border: 1px solid #99d1ff;
}

.file-explorer .reload-container {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.file-explorer .content-body .filebox-drop-area {
    display: none;
}

.file-explorer .content-body.drag-over .filebox-drop-area,
.file-explorer .content-body.empty-folder .filebox-drop-area {
    position: absolute;
    display: block;
    height: 100%;
    width: 100%;
    z-index: 10;
    padding: 20px;
    margin: 0;
    cursor: pointer;
}

.file-explorer .content-body.drag-over .filebox-drop-area,
.file-explorer .content-body.empty-folder .filebox-drop-area:hover {
    background-color: #eeeeee88;
}

.file-explorer .content-body.drag-over .filebox-drop-area .drop-area-inner,
.file-explorer .content-body.empty-folder .filebox-drop-area:hover .drop-area-inner {
    height: 100%;
    width: 100%;
    border: 2px dashed #3ca8d2;
    background-color: #ffffff88;
    user-select: none;
    pointer-events: none;
}

.file-explorer .content-body.drag-not-allow .filebox-drop-area {
    position: absolute;
    display: block;
    height: 100%;
    width: 100%;
    z-index: 10;
    padding: 20px;
    margin: 0;
    background-color: #aaaaaa99;
    cursor: not-allowed;
}

.file-explorer .content-body.drag-not-allow .filebox-drop-area .drop-area-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    border: 2px dashed #3ca8d2;
    background-color: #ffffff88;
    color: #ccc;
    padding: 20%;
    user-select: none;
    pointer-events: none;
    cursor: not-allowed;
}

.file-explorer .content-body.drag-not-allow .filebox-drop-area .drop-area-inner svg {
    height: 100%;
    width: 100%;
}

.file-explorer .accordion-group {
    height: 50px;
    min-height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition-duration: 0.4s;
    transition-timing-function: ease;
}

.file-explorer .accordion-group.active {
    height: 100%;
}

.file-explorer .accordion-group.active .header {
    cursor: unset;
    font-weight: 500;
}

.file-explorer .accordion-group:not(.active) .header:hover {
    background-color: #d4d4d4;
    color: #1069d5;
}

.file-explorer .accordion-group .header {
    position: relative;
    width: 100%;
    height: 50px;
    min-height: 50px;
    padding: 8px;
    display: flex;
    cursor: pointer;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #e2e2e8;
}

.file-explorer .accordion-group .header span {
    width: 100%;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.file-explorer .accordion-group .detail {
    height: 100%;
    width: 100%;
    position: relative;

}

.file-explorer .accordion-group:first-child .header {
    border-top: none;
}
